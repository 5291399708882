<template>
  <div class="channel-container" v-if="!isChannel">
    <div v-if="store.state.isMobile">
      <SGYNotice :text="text"></SGYNotice>
    </div>
    <p class="title wall-title" v-if="!store.state.isMobile">相约而遇</p>
    <p class="slogan" v-if="!store.state.isMobile">{{ text }}</p>

    <div class="operate">
      <div class="label">
        <p class="label-item labelActive">全部</p>
        <p class="label-item" @click="toHall">大厅</p>
      </div>
      <div class="room-number">
        <input class="room-number-input" type="search" placeholder="房间名或房间号" v-model="searchRoomNumber">
        <div class="room-number-button" v-if="isSearch" @click="onSearch">查找</div>
        <div class="room-number-button" v-else @click="onReset">重置</div>
      </div>
    </div>

    <div class="room-box">
      <ExclusiveRoomVue v-for="item in chatroomData" :key="item.id" :chatroom="item"
        @toChatroom="onToChatroom(item.roomNumber)">
      </ExclusiveRoomVue>
    </div>
    <!-- 加载状态 -->
    <SGYShowDateStatus :isAnimation="isAnimation" :page="paramsData.page" :length="chatroomData.length">
    </SGYShowDateStatus>
    <!-- 添加与刷新 -->
    <SGYBtnAddRefresh :isRefresh="isRefresh" :addBottom="addBottom" :isModal="isModal" @onRefresh="onRefresh"
      @openModal="openModal"></SGYBtnAddRefresh>

    <RoomFormVue v-if="isModal" @close="onCloseModal" @load="onLoad"></RoomFormVue>
  </div>
  <router-view v-else />
</template>

<script>
export default { name: 'ChannelMessage' };
</script>

<script setup>
import SGYNotice from '@/components/SGYNotice.vue';
import SGYBtnAddRefresh from "@/components/SGYBtnAddRefresh.vue";
import SGYShowDateStatus from "@/components/SGYShowDataStatus.vue";
import ExclusiveRoomVue from '@/components/ExclusiveRoom.vue';
import RoomFormVue from '@/components/RoomForm.vue';
import { findRoomsApi } from '@/api/index';
import { useStore } from 'vuex';
import { onMounted, ref, computed, defineEmits } from 'vue';
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router';
import Message from '@/components/SGY/message/message';

let text = '一处充满魅力的交流空间，既有私密的角落供您与挚友倾诉心声，又有开放的广场让您结识志同道合的新友。在这里，每一次交流都如同一次优雅的邂逅，为您的沟通之旅增添无限色彩与诗意';
const store = useStore();
const router = useRouter();
const route = useRoute();
const emit = defineEmits( ['hideFooter'] );

const isModal = ref( false );
const isRefresh = ref( false );
const addBottom = ref( store.state.isMobile ? 100 : 40 );
const chatroomData = ref( [] );
const isAnimation = ref( -1 ); // 动画 -1 加载中 0 数据为空 1 无更多数据(加载完成)
const lastWallCardCall = ref( '' ); // 上次调用时间
const searchRoomNumber = ref( null ); // 搜索的房间号
const isSearch = ref( true ); // true搜索， false重置
const paramsData = ref( {
  page: 1,
  pagesize: 15,
} );

const modeHeight = computed( () => store.state.isMobile ? 200 : 250 );
const isChannel = computed( () => route.path.includes( '/channelDetail' ) );

const onToChatroom = ( roomNumber ) => {
  emit( 'hideFooter' );
  router.push( `/channelDetail/${ roomNumber }` );
};

// 打开弹窗
const openModal = () => {
  isModal.value = true;
};

// H5刷新按钮
const onRefresh = async () => {
  isRefresh.value = true;
  paramsData.value.page = 1;
  await findRoomsFn();
  window.scrollTo( 0, 0 );
  setTimeout( () => {
    isRefresh.value = false;
    Message( { type: 'success', message: '刷新成功~~~' } );
  }, 1000 );
  onCloseModal();
};

// 关闭
const onCloseModal = () => {
  isModal.value = false;
};

// 重新加载数据
const onLoad = () => {
  paramsData.value.page = 1;
  onCloseModal();
  findRoomsFn();
};

// 获取聊天室列表
const findRoomsFn = async () => {
  if ( throttle() && paramsData.value.page > 0 ) {
    isAnimation.value = -1;
    try {
      let res = await findRoomsApi( paramsData.value );
      if ( Number( res.code ) === 200 ) {
        if ( paramsData.value.page == 1 ) {
          chatroomData.value = [];
          chatroomData.value = res.message;
        } else {
          chatroomData.value = chatroomData.value.concat( res.message );
        }
        res.message.length < paramsData.value.pagesize ? paramsData.value.page = 0 : paramsData.value.page++; // page处理
      }
    } catch ( err ) {
      console.log( err );
      Message( { message: '获取列表失败', type: 'error' } );
    } finally {
      hasContentStatus();
    }
  }
};

// 节流
const throttle = () => {
  const now = Date.now();
  const lastCall = lastWallCardCall.value || 0;
  // 如果上次调用在1秒内，则不执行API调用  
  if ( now - lastCall < 300 ) {
    return false;
  } else {
    lastWallCardCall.value = now; // 更新上次调用时间  
    return true;
  }
};

/** 有无内容状态*/
const hasContentStatus = () => {
  if ( chatroomData.value.length > 0 ) {
    isAnimation.value = 1;
  } else {
    isAnimation.value = 0;
  }
};

// 去大厅
const toHall = () => {
  router.push( `/channelDetail/1` );
};

// 搜索
const onSearch = async () => {
  try {
    if ( searchRoomNumber.value.trim() !== '' ) {
      let res = await findRoomsApi( { roomNumber: searchRoomNumber.value } );
      if ( Number( res.code ) === 200 ) {
        if ( res.message.length > 0 ) {
          chatroomData.value = res.message;
          isSearch.value = false;
        } else {
          Message( { message: '没有该房间', type: 'warning' } );
        }
      }
    }
  } catch ( err ) {
    console.log( err );
    Message( { message: '查找失败', type: 'error' } );
  } finally {
    hasContentStatus();
  }
};

// 重置
const onReset = () => {
  isSearch.value = true;
  searchRoomNumber.value = null;
  paramsData.value.page = 1;
  findRoomsFn();
};

// 滚动加载
const onScrollBottom = () => {
  // 距离顶部距离
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  // 视口高度
  let clientHeight = document.documentElement.clientHeight;
  // 内容高度
  let scrollHeight = document.documentElement.scrollHeight;
  if ( store.state.isMobile ) {
    addBottom.value = 100;
  } else {
    if ( scrollTop + clientHeight + modeHeight.value >= scrollHeight ) {
      addBottom.value = scrollTop + clientHeight + modeHeight.value - scrollHeight;
    } else {
      addBottom.value = 40;
    }
  }
  // 加载更多卡片
  if ( Math.ceil( scrollTop + clientHeight ) + 150 >= scrollHeight ) {
    findRoomsFn();
  }
};

findRoomsFn();

onBeforeRouteUpdate( ( to, from ) => {
  if ( from.name === 'ChannelMessageDetail' ) {
    paramsData.value.page = 1;
    findRoomsFn();
  }
} );
onMounted( () => {
  window.addEventListener( 'scroll', () => { onScrollBottom(); } );
} );
</script>
<style lang="less" scoped>
.channel-container {
  position: relative;
  min-height: 88%;
  text-align: center;

  .title {
    padding-top: 50px;
    padding-bottom: @padding-8;
    font-size: 3.2vw;
    color: @gray-1;
    font-weight: 600;
  }

  .slogan {
    padding: 6px 20px;
    color: @gray-2;
  }


  .operate {
    .flex;
    gap: 30px;
    margin: 10px auto;

    .label {
      .flex;
      gap: 10px;

      .label-item {
        padding: @padding-4 18px;
        line-height: 16px;
        color: @gray-2;
        box-sizing: border-box;
        border: 1px solid transparent;
        border-radius: 16px;
        cursor: pointer;
        transition: @tr;
        font-size: @size-14;
        font-weight: 600;
      }

      .labelActive {
        color: @gray-1;
        border: 1px solid @gray-1;
        border-radius: 16px;
        box-shadow: inset 0 1px 3px 0 @primary-color;
      }
    }

    .room-number {
      border-radius: 16px;
      overflow: hidden;
      font-size: 16px;
      position: relative;
      box-shadow: inset 0 1px 3px 0 @primary-color;


      .room-number-input {
        width: 300px;
        max-width: 50vw;
        padding: 5px 60px 5px 10px;
        border: 1px solid @gray-10;
        background-color: transparent;
        color: @gray-1;
      }

      .room-number-button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50px;
        background-color: @primary-color;
        color: @gray-10;
        font-size: 14px;
        cursor: pointer;
        .flex;
      }
    }
  }

  .room-box {
    margin: 0 auto;
    padding: 1vw 0;
    max-width: 87vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
    grid-gap: 10px 10px;
    cursor: pointer;
    transition: @tr;
  }
}
</style>