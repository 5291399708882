import fetch from '@/utils/axios';

/**获取用户IP */
export const getIpApi = () => fetch.post( '/signip' );

/** 新建wall */
export const insertWallApi = data => fetch.post( '/insertWall', data );

/** 获取wall */
export const findWallPageApi = data => fetch.post( '/findWallPage', data );

/** 留言反馈 */
export const insertFeedbackApi = data => fetch.post( '/insertFeedback', data );

/** 提交留言 */
export const insertCommentApi = data => fetch.post( '/insertComment', data );

/** 获取评论 */
export const findCommentPageApi = data => fetch.post( '/findCommentPage', data );

/** 上传图片 */
export const uploadImgApi = data => fetch.post( '/profile', data );

/** 注册 */
export const insertRegisterApi = data => fetch.post( '/insertRegister', data );

/** 验证码获取 */
export const getVerifyCodeApi = data => fetch.post( '/getVerifyCode', data );

/** 登录 */
export const findUserLoginApi = data => fetch.post( '/findUserLogin', data );

/** 修改账号密码 */
export const updatePasswordApi = data => fetch.post( '/updatePassword', data );

/** 获取用户信息 */
export const getUserInfoApi = data => fetch.post( '/getUserInfo', data );

/** 修改昵称和头像 */
export const updateUserInfoApi = data => fetch.post( '/updateUserInfo', data );

/** 退出登录 */
export const logoutApi = () => fetch.post( '/logout' );

/** 删除卡片 */
export const deleteWallApi = data => fetch.post( '/deleteWall', data );

/** 删除留言 */
export const deleteCommentApi = data => fetch.post( '/deleteComment', data );

/** 获取最新卡片 */
export const getLatestCardsApi = data => fetch.post( '/getLatestCards', data );

/** 新增胶囊 */
export const insertCapsulesApi = data => fetch.post( '/insertCapsules', data );

/** 删除胶囊 */
export const deleteCapsulesApi = data => fetch.post( '/deleteCapsules', data );

/** 删除胶囊 */
export const updateCapsulesApi = data => fetch.post( '/updateCapsules', data );

/** 获取胶囊 */
export const findCapsulesApi = data => fetch.post( '/findCapsules', data );

/** 新增房间 */
export const insertRoomsApi = data => fetch.post( '/insertRooms', data );

/** 删除房间 */
export const deleteRoomsApi = data => fetch.post( '/deleteRooms', data );

/** 删除房间 */
export const updateRoomsApi = data => fetch.post( '/updateRooms', data );

/** 获取房间 */
export const findRoomsApi = data => fetch.post( '/findRooms', data );

/** 获取大厅 */
export const findHallRoomsApi = data => fetch.post( '/findHallRooms', data );

/** 验证房间密钥 */
export const verifyRoomKeyApi = data => fetch.post( '/verifyRoomKey', data );

/** 房间新增用户 */
export const insertRoomMembersApi = data => fetch.post( '/insertRoomMembers', data );

/** 房间删除用户 */
export const deleteRoomMembersApi = data => fetch.post( '/deleteRoomMembers', data );

/** 查询房间内的用户 */
export const findRoomMembersApi = data => fetch.post( '/findRoomMembers', data );

/** 修改房间用户的状态 */
export const updateRoomMembersStatusApi = data => fetch.post( '/updateRoomMembersStatus', data );

/** 查询房间内的消息 */
export const findRoomMessagesApi = data => fetch.post( '/findRoomMessages', data );

/** 获取用户发布数据 */
export const findMyPublishDataApi = data => fetch.post( '/findMyPublishData', data );

/** 获取最新版本 */
export const getLatestVersionApi = () => fetch.post( '/getLatestVersion' );

/** 获取用户未查看的通知 */
export const findNotifysApi = data => fetch.post( '/findNotifys', data );

/** 移除用户已查看通知 */
export const deleteNotifysApi = data => fetch.post( '/deleteNotifys', data );
