import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import Vuex from './store/index';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
/** 防扒 */
import { bug } from './utils/bug';
bug();

/**注册自己的组件 */
import SGY from './components/SGY/index';

const app = createApp( App );
app.component( 'QuillEditor', QuillEditor );
app.use( router );
app.use( Vuex );
app.use( SGY );

// import Vconsole from 'vconsole';
// app.use( new Vconsole() );

app.mount( '#app' );