<template>
  <transition name="modal" @click.stop>
    <div class="sgy-modal" :style="occupied ? 'top:0;height:100%;' : ''" ref="modalRef" v-if="isModal">
      <div class="sgy-modal-head">
        <p class="modal-name">{{ title }}</p>
        <span class="iconfont icon-guanbi" @click.stop="onCloseModal" @close="close"></span>
      </div>
      <div class="main" @click.stop>
        <slot class="slot"></slot>
      </div>
    </div>
  </transition>
</template>
<script setup>
import { ref, defineProps, defineEmits, onUnmounted, watch } from "vue";
const modalRef = ref( '' );
const props = defineProps( {
  title: {
    default: "标题",
  },
  isModal: {
    default: false,
  },
  occupied: {
    default: false,
    type: Boolean,
  }
} );

const emit = defineEmits( ["close"] );
const onCloseModal = () => {
  emit( "close" );
};

watch( () => props.isModal, ( nv ) => {
  if ( !nv ) { return; }
  setTimeout( () => {
    modalRef.value.addEventListener( 'touchmove', e => e.stopPropagation(), false );
  }, 10 );
}, );

onUnmounted( () => {
  onCloseModal;
} );
</script>
<style lang="less" scoped>
.sgy-modal {
  position: fixed;
  top: 60px;
  right: 0;
  width: 360px;
  height: calc(100% - 60px);
  background-color: @gray-10-8;
  backdrop-filter: blur(50px);
  box-shadow: 0 0 12px 4px @gray-0-2;
  z-index: 90;
  box-sizing: border-box;

  .sgy-modal-head {
    .flex-b;
    padding: @padding-20;
    padding-bottom: 10px;

    .modal-name {
      font-size: @size-16;
      color: @gray-1;
      font-weight: 550;
    }

    .icon-guanbi {
      color: @gray-2;
      cursor: pointer;
      padding-left: 8px;
    }
  }

  .main {
    height: calc(100% - 54px - 80px);
    width: 100%;
    box-sizing: border-box;
    overflow: auto;

    .slot {
      height: 900px;
      margin-left: @padding-20;
      margin-right: @padding-12;
      box-sizing: border-box;
      text-align: left;
    }
  }
}

.modal {

  // 入场动画
  &-enter {
    &-from {
      transform: translateX(360px);
      // opacity: 1;
    }

    &-active {
      transition: all 0.3s ease-out;
    }

    &-to {
      transform: translateX(0px);
      // opacity: 0.6;
    }
  }

  &-leave {
    &-from {
      transform: translateX(0px);
      // opacity: 1;
    }

    &-active {
      transition: all 0.3s ease-in;
    }

    &-to {
      transform: translateX(360px);
      // opacity: 0.3;
    }
  }
}
</style>
