<template>
  <div class="room-form-container" @click="emit('close')">
    <div class="form-box" @click.stop>
      <div class="title">{{ chatroomInfo ? '房间信息' : '创建房间' }}</div>
      <!-- 创建 -->
      <div class="form-content" v-if="!chatroomInfo">
        <div class="form">
          <div class="key">房间名称:</div>
          <div class="value">
            <input type="text" class="input" placeholder="请输入房间名称" v-model="paramsData.title" />
          </div>
        </div>
        <div class="form">
          <div class="key">房间描述:</div>
          <div class="value">
            <textarea class="input textarea" placeholder="请输入房间描述" v-model="paramsData.message"></textarea>
          </div>
        </div>
      </div>
      <!-- 修改 或 展示 -->
      <div class="form-content" v-else>
        <div class="form">
          <div class="key">房间名称:</div>
          <div class="value">
            <input type="text" class="input" placeholder="请输入房间名称" v-model="chatroomInfo.title" v-if="isRoomAdmin" />
            <div class="div" v-else>{{ chatroomInfo.title }}</div>
          </div>
        </div>
        <div class="form">
          <div class="key">房间描述:</div>
          <div class="value">
            <textarea class="input textarea" placeholder="请输入房间描述" v-model="chatroomInfo.message" v-if="isRoomAdmin">
            </textarea>
            <div class="div" v-else>{{ chatroomInfo.message }}</div>
          </div>
        </div>
        <div class="form" v-if="!isHall">
          <div class="key">房间编号:</div>
          <div class="value">
            <div class="div copyable">{{ chatroomInfo.roomNumber }}
              <i class="iconfont icon-fuzhi" @click.stop="onCopyRoomKey(chatroomInfo.roomNumber)"></i>
            </div>
          </div>
        </div>
        <div class="form" v-if="!isHall">
          <div class="key">房间状态:</div>
          <div class="value radio-box" v-if="isRoomAdmin">
            <div class="radio" :class="chatroomInfo.status === 1 && 'radioActive'" @click="onRoomStatusChange(1)">使用中
            </div>
            <div class="radio" :class="chatroomInfo.status === 0 && 'radioActive'" @click="onRoomStatusChange(0)">已停用
            </div>
          </div>
          <div class="value radio-box" v-else>
            <div class="div">{{ chatroomInfo.status == 1 ? '使用中' : '已停用' }}
            </div>
          </div>
        </div>
        <div class="form" v-if="!isHall">
          <div class="key">房间人数:</div>
          <div class="value">
            <div class="div">{{ chatroomInfo.memberCount }}</div>
          </div>
        </div>
        <div class="form" v-if="!isHall">
          <div class="key">创建时间:</div>
          <div class="value">
            <div class="div">{{ formatDate(chatroomInfo.createTime) }}</div>
          </div>
        </div>
        <div class="member-list" v-if="isMobile && !isHall">
          <div class="key">房间成员</div>
          <div class="list">
            <MemberDetailVue :memberInfo="item" :memberActiveId="memberActiveId" :top="120" :left="30"
              :isRoomAdmin="isRoomAdmin" v-for="item in memberData" :key="item.id"
              :roomUserId="Number(chatroomInfo.userId)" @click="showMemberInfo(item.id)" @close="memberActiveId = -1"
              @kickOut="onKickOutMember(item.userId)" @silenceClient="onSilenceClient(item.userId, item.status)">
            </MemberDetailVue>
          </div>
        </div>
      </div>
      <div class="form-submit">
        <SGYButton medium line @click="emit('close')">关闭</SGYButton>
        <SGYButton medium line v-if="!chatroomInfo" @click="insertRoomsFn">创建</SGYButton>
        <SGYButton medium line v-if="isRoomAdmin" @click="deleteRoomsFn">删除</SGYButton>
        <SGYButton medium line v-if="isRoomAdmin && !confirmEdit" @click="confirmEdit = true">修改</SGYButton>
        <SGYButton medium line v-if="isRoomAdmin && confirmEdit" @click="updateRoomsFn">确认</SGYButton>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'RoomForm' };
</script>
<script setup>
import SGYButton from '@/components/SGYButton.vue';
import Message from '@/components/SGY/message/message';
import MemberDetailVue from '@/components/MemberDetail.vue';
import { formatDate, onCopyRoomKey } from '@/utils/handleFn';
import { insertRoomsApi, deleteRoomsApi, updateRoomsApi, deleteRoomMembersApi, updateRoomMembersStatusApi } from '@/api/index';
import eventBus from '@/utils/eventBus';
import { defineProps, defineEmits, ref, computed } from 'vue';
import { useStore } from 'vuex';
import showDialog from '@/components/SGY/ShowDialog/ShowDialog';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const emit = defineEmits( ['close', 'load', 'kickClient', 'mobileSilenceClient'] );
const props = defineProps( { 'chatroomInfo': Object, 'memberData': Array, isHall: { type: Boolean, default: false } } );
const chatroomInfo = ref( ( props.chatroomInfo && JSON.parse( JSON.stringify( props.chatroomInfo ) ) ) || null );
const memberActiveId = ref( -1 );
const confirmEdit = ref( false );
const paramsData = ref( {
  userId: store.state.user?.id || '',
  title: '',
  message: '',
  status: 1,
  type: 1,
  createTime: new Date(),
} );

const isRoomAdmin = computed( () => Number( store.state.user?.id ) === Number( chatroomInfo.value?.userId ) );
const isMobile = computed( () => store.state.isMobile );
const isHall = computed( () => props.isHall );
const memberData = computed( () => props.memberData );

const deleteRoomsFn = async () => {
  showDialog( {
    title: '删除房间', message: '确定要删除该房间吗？', cancel: true, onConfirm: async () => {
      try {
        let res = await deleteRoomsApi( { id: chatroomInfo.value.id, userId: chatroomInfo.value.userId } );
        if ( res.code === 200 ) {
          router.replace( { 'path': '/channel' } );
          eventBus.$emit( 'deleteRoom', chatroomInfo.value.roomNumber ); // 触发删除房间事件 - 将所有用户踢出该房间
          Message( { message: res.message, type: 'success' } );
        }
      } catch ( err ) {
        if ( err.response.status === 401 ) {
          Message( { message: '访问该页面请先登录', type: 'error' } );
        } else if ( err.response.status === 403 ) {
          Message( { message: '令牌无效，请重新登录', type: 'error' } );
        } else {
          Message( { message: '房间删除失败', type: 'error' } );
        }
      }
    }
  } );
};

const updateRoomsFn = async () => {
  try {
    let res = await updateRoomsApi( { title: chatroomInfo.value.title, message: chatroomInfo.value.message, status: chatroomInfo.value.status, id: chatroomInfo.value.id, userId: chatroomInfo.value.userId, } );
    if ( res.code === 200 ) {
      emit( 'load' );
      Message( { message: res.message, type: 'success' } );
    }
  } catch ( err ) {
    if ( err.response.status === 401 ) {
      Message( { message: '访问该页面请先登录', type: 'error' } );
    } else if ( err.response.status === 403 ) {
      Message( { message: '令牌无效，请重新登录', type: 'error' } );
    } else {
      Message( { message: '房间修改失败', type: 'error' } );
    }
  }
};

const insertRoomsFn = async () => {
  if ( !dataCheck() ) return;
  try {
    const res = await insertRoomsApi( paramsData.value );
    if ( res.code === 200 ) {
      emit( 'load' );
      Message( { message: res.message, type: 'success' } );
    }
  } catch ( err ) {
    if ( err.response.status === 401 ) {
      Message( { message: '访问该页面请先登录', type: 'error' } );
    } else if ( err.response.status === 403 ) {
      Message( { message: '令牌无效，请重新登录', type: 'error' } );
    } else {
      Message( { message: '房间创建失败', type: 'error' } );
    }
  }
};

const dataCheck = () => {
  if ( !paramsData.value.title || paramsData.value.title.trim() == '' ) {
    console.log( paramsData.value.title );
    Message( { message: '请输入房间名', type: 'error' } );
    return false;
  }
  if ( paramsData.value.message == '' || !paramsData.value.message ) {
    Message( { message: '请输入房间描述', type: 'error' } );
    return false;
  }
  return true;
};

// 踢人
const onKickOutMember = async ( userId ) => {
  try {
    let res = await deleteRoomMembersApi( { userId: userId, roomId: chatroomInfo.value.id } );
    if ( res.code === 200 ) {
      Message( { message: '删除成功', type: 'success' } );
      emit( 'load' );
      emit( 'kickClient', userId );
    }
  } catch ( error ) {
    Message( { message: '删除失败，请重试', type: 'error' } );
  }
};

const onSilenceClient = async ( userId, status ) => {
  status = status == 1 ? 0 : 1; // 修改禁言状态
  try {
    let res = await updateRoomMembersStatusApi( { userId: userId, roomId: chatroomInfo.value.id, status: status } );
    emit( 'mobileSilenceClient', userId, status );
    Message( { message: res.message, type: 'success' } );
  } catch ( error ) {
    console.log( error );
    Message( { message: '禁言失败，请刷新重试', type: 'error' } );
  }
};

const onRoomStatusChange = ( flag ) => {
  chatroomInfo.value.status = flag;
};

const showMemberInfo = ( id ) => {
  if ( memberActiveId.value != id ) {
    memberActiveId.value = id;
  } else {
    memberActiveId.value = -1;
  }
};

</script>
<style lang="less" scoped>
.room-form-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 88;
  background-color: @gray-10-6;

  .form-box {
    position: relative;
    margin: 12% auto;
    width: 30%;
    background-color: @gray-10-8;
    backdrop-filter: blur(10px);
    box-shadow: 5px 5px 15px @gray-1-2;
    position: relative;
    border-radius: 20px;
    padding: 30px 30px 60px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .title {
      font-size: 20px;
      color: @gray-1;
    }

    .form-content {
      margin: 20px 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 15px;

      .form {
        .flex-s;
        align-items: flex-start;
        color: @gray-1;
        text-shadow: 1px 1px 1px @gray-1-2;
        gap: 6px;

        .key {
          flex: 20%;
          min-width: 70px;
        }

        .value {
          flex: 80%;

          .input {
            width: 100%;
            padding: 5px 10px;
            color: @gray-1;
            border-bottom: 1px solid @gray-10;
            background-color: transparent;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            box-shadow: inset 5px 1px 5px @gray-1-2;
            border-radius: 5px;
          }

          .textarea {
            height: 100px;
            resize: none;
            white-space: wrap;
            overflow: auto;
            text-overflow: unset;
          }

          .div {
            color: @gray-1;

            .icon-fuzhi {
              margin-left: 2px;
              cursor: pointer;
            }
          }

          .copyable {
            .copyable;
          }

          .disabled {
            background-color: @gray-3-4;
          }
        }

        .radio-box {
          text-align: left;

          .radio {
            display: inline-block;
            cursor: pointer;
            font-size: 12px;
            padding: 2px 16px;
            background-color: @gray-3-4;
            color: @gray-1;
          }

          .radioActive {
            background-color: @success-color !important;
          }
        }
      }
    }

    .member-list {
      margin-bottom: 20px;
      height: calc(100% - 180px);
      width: 100%;
      .flex-s;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .key {
        font-size: 16px;
      }

      .list {
        width: 100%;
        max-height: calc(100% - 124px);
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(50px, 6fr));
        justify-items: center;
        grid-gap: 6px;
        overflow: auto;
      }
    }

    .form-submit {
      position: absolute;
      bottom: 20px;
      right: 20px;
      .flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}
</style>