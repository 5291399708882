<template>
  <div class="photo-card" v-if="card.imgurl">
    <img :src="baseUrl + imgType + card.imgurl" ref="imgRef" class="photo-img" @load="isDot = true" />
    <div class="mask" @click.stop="toDetail" ref="maskRef">
      <div class="toolbar">
        <div class="like-box">
          <span class="iconfont icon-aixin" :class="{ like: card.islike[0].count > 0 }"
            @click.stop="onClickLike"></span>
          <span class="like-number" v-if="card.like[0].count > 0">{{ card.like[0].count }}</span>
        </div>
        <div class="comment" v-if="card.comcount[0].count > 0">
          <span class="iconfont icon-liuyan"></span>
          <span class="like-number">{{ card.comcount[0].count }}</span>
        </div>
      </div>
    </div>
    <div title="待撕掉(￣▽￣)" class="wait-delete" v-if="isDot && card.cancel[0].count > 0"></div>
    <div title="被举报啦＞﹏＜" class="have-report" v-if="isDot && card.report[0].count > 0"></div>
    <div title="有新消息" class="have-notify-sub" v-if="isDot && card.hasNotify"></div>
  </div>
</template>

<script>
import { labels, cardColor } from "@/utils/data";
import { baseUrl } from "@/utils/env";
import { insertFeedbackApi } from "@/api/index";
export default {
  props: {
    photo: {
      default: {},
    }
  },
  computed: {
    card () {
      return this.photo;
    },
  },

  data () {
    return {
      labels,
      cardColor,
      baseUrl,
      isDot: false,
      userId: this.$store.state.user?.id || this.$store.state.ip,
      imgType: '/small/'
    };
  },

  methods: {
    toDetail () {
      this.$emit( 'toDetail' );
    },
    async onClickLike () {
      if ( this.card.islike[0].count == 0 ) {
        this.card.islike[0].count = 1;
        this.card.like[0].count++;
      } else {
        this.$message( { type: 'warning', message: '已点赞' } );
        return false;
      }
      await insertFeedbackApi( {
        wallId: this.card.id,
        userId: this.userId,
        type: 0, // 0 是喜欢
        moment: new Date()
      } ).then( ( res ) => {
        if ( res.code == 200 ) {
          this.$message( { type: 'success', message: '点赞成功' } );
          this.$emit( 'clearCache' );
        }
      } );
    },

  },
};
</script>
<style lang="less" scoped>
.photo-card {
  position: relative;

  .photo-img {
    width: 100%;
    object-fit: cover;
  }

  .mask:hover {
    opacity: 1;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(1px);
    opacity: 0;
    transition: @tr;
    cursor: pointer;

    .toolbar {
      position: absolute;
      top: 10px;
      left: 10px;
      padding: 1px 8px;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(1px);
      .flex-b;
      flex-direction: row;
      gap: 10px;

      .comment,
      .like-box {
        .flex;
        gap: 3px;
        font-size: @size-12;
        color: rgba(255, 255, 255, 0.7);

        .iconfont {
          font-size: @size-12;
          cursor: pointer;
          transition: @tr;
        }

        .icon-aixin {
          font-size: 11px;

          &:hover {
            color: @like;
          }
        }

        .like {
          color: @like;
        }
      }
    }
  }

  .wait-delete,
  .have-report {
    position: absolute;
    top: 0;
    right: 24px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: @primary-color;
    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.3);
  }

  .have-report {
    right: 10px;
    background: @success-color;
  }

  .have-notify-sub {
    transform: translate(10px, 0);
  }
}
</style>
