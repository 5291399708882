import { io } from "socket.io-client";
import { baseUrl } from '@/utils/env';
import eventBus from "@/utils/eventBus";
import router from '../router/index';
import Message from "@/components/SGY/message/message";

const socket = io( baseUrl, { // 本地 http://localhost:9001 - 正式 http://47.116.120.164:9001
  path: "/api/socket/", // 连接接口
  transports: ["websocket", "polling"], // 优先使用WebSocket协议
  withCredentials: true, // 允许携带cookie
  // autoUnref: true, // 允许服务端自动取消引用
  reconnection: true, // 允许自动重连
  reconnectionDelay: 1000, // 重连间隔
  reconnectionDelayMax: 5000, // 最大重连间隔
  reconnectionAttempts: 5, // 重连次数
  timeout: 5000, // 超时时间
} );

export class SocketService {
  constructor ( roomNumber, user, receiveMessage ) {
    this.socket = socket;
    this.roomNumber = 'room' + roomNumber;
    this.user = user;
    this.reconnectionNumber = 10;
    // 方法
    this.initSocket();
    this.receiveMessage = receiveMessage;
  }

  initSocket () {
    this.socket.on( 'connect', () => { // 监听连接
      console.log( 'socket连接成功' );
    } );
    this.socket.on( 'disconnect', () => { // 监听断开连接
      console.log( 'socket断开连接' );
      this.leaveRoom();
      this.dispose();
    } );
    this.socket.on( 'disconnecting', () => { // 监听意外断开连接(客户端崩溃，网络断开)
      console.log( 'socket意外断开连接' );
      if ( this.reconnectionNumber > 0 ) {
        this.auth();
        this.reconnectionNumber--;
      } else {
        this.leaveRoom();
        this.dispose();
      }
    } );
    this.socket.on( "connect_error", () => { // 连接失败
      console.log( "socket连接失败" );
      socket.io.opts.transports = ["polling", "websocket"];
    } );
    this.socket.on( 'auth_err', ( e ) => { console.log( e ); } );
    this.socket.on( 'auth_suc', () => { this.joinRoom(); } );
  }

  auth () {
    this.socket.emit( 'auth', { room: this.roomNumber, userId: this.user.id } );
  }

  joinRoom () {
    this.socket.emit( 'joinRoom', { room: this.roomNumber, userId: this.user.id } );
    this.watchMessage();
    this.watchKick();
    this.watchSilence();
    this.watchDeleteRoom();
  }

  leaveRoom () {
    this.socket.emit( 'leaveRoom', { room: this.roomNumber, userId: this.user.id } );
    this.dispose();
  }

  deleteRoom ( deleteRoomId ) {
    this.socket.emit( 'deleteRoom', { room: 'room' + deleteRoomId } );
  }

  watchDeleteRoom () {
    this.socket.on( 'deleteRoom', () => {
      router.push( '/channel' );
      this.socket.disconnect(); // 断开连接
      this.dispose();
      Message( { message: '房间已解散', type: 'warning' } );
    } );
  }

  sendMessage ( roomId, message ) {
    this.socket.emit( 'sendMessage', { roomId, room: this.roomNumber, user: this.user, message } );
  }

  watchMessage () {
    this.socket.on( 'message', ( data ) => {
      if ( data.room === this.roomNumber ) this.receiveMessage( data );
    } );
  }

  kickClient ( userId ) {
    this.socket.emit( 'kickClient', { userId: userId, room: this.roomNumber } );
  }

  watchKick () {
    this.socket.on( 'kick', () => {
      router.push( '/channel' );
      this.socket.disconnect(); // 断开连接
      this.dispose();
      Message( { message: '您已被踢出房间', type: 'warning' } );
    } );
  }

  silenceClient ( userId, status ) {
    this.socket.emit( 'silenceClient', { userId: userId, room: this.roomNumber, status: status } );
  }

  watchSilence () {
    this.socket.on( 'silence', ( status ) => {
      if ( Number( status ) === 1 ) {
        Message( { message: '您已被禁言', type: 'warning' } );
      } else {
        Message( { message: '您已取消禁言', type: 'success' } );
      }
      eventBus.$emit( 'memberRefresh' );
    } );
  }

  dispose () {
    // 如果还有其他事件监听器，也在这里取消它们  
    this.socket.off( 'connect' );
    this.socket.off( 'disconnect' );
    this.socket.off( 'disconnecting' );
    this.socket.off( 'connect_error' );
    this.socket.off( 'message' );
    this.socket.off( 'auth_err' );
    this.socket.off( 'auth_suc' );
  }
}