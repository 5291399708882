<template>
  <div class="sgy-note-card-container" :style="{ background: cardColor[card.color] }">
    <div class="sgy-note-card-inner">
      <div class="top">
        <p class="time">{{ formatDate(card.moment) }}</p>
        <p class="label">{{ labels[card.type][card.label] }}</p>
      </div>
      <p class="message" @click.stop="toDetail">{{ card.message }}</p>
      <div class="foot">
        <div class="foot-left">
          <span v-if="!isNews">
            <span class="iconfont icon-aixin" :class="{ like: card.islike[0].count > 0 }" @click="onClickLike"></span>
            {{ card.like[0].count }}
          </span>
          <span v-show="card.comcount[0].count > 0">
            <span class="iconfont icon-liuyan"></span>
            {{ card.comcount[0].count }}
          </span>
        </div>
        <div class="right-name">{{ card.name }}</div>
      </div>
    </div>
    <div title="待撕掉(￣▽￣)" class="wait-delete" v-if="card.cancel[0].count > 0"></div>
    <div title="被举报啦＞﹏＜" class="have-report" v-if="card.report[0].count > 0"></div>
    <div title="有新消息" class="have-notify-sub" v-if="card.hasNotify"></div>
  </div>
</template>

<script>
import { labels, cardColor } from "@/utils/data";
import { formatDate } from "@/utils/handleFn";
import { insertFeedbackApi } from "@/api/index";
export default {
  props: {
    note: {
      default: {},
    },
    isNews: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    card () {
      return this.note;
    },
  },
  data () {
    return {
      labels,
      cardColor,
      formatDate,
      userId: this.$store.state.user?.id || this.$store.state.ip,
    };
  },
  created () { },
  methods: {
    toDetail () {
      this.$emit( 'toDetail' );
    },
    async onClickLike () {
      if ( this.card.islike[0].count == 0 ) {
        this.card.islike[0].count = 1;
        this.card.like[0].count++;
      } else {
        this.$message( { type: 'warning', message: '已点赞' } );
        return false;
      }
      await insertFeedbackApi( {
        wallId: this.card.id,
        userId: this.userId,
        type: 0, // 0 喜欢
        moment: new Date()
      } ).then( ( res ) => {
        if ( res.code == 200 ) {
          this.$message( { type: 'success', message: '点赞成功' } );
          this.$emit( 'clearCache' );
        }
      } );
    }
  },
};
</script>
<style lang="less" scoped>
.sgy-note-card-container {
  position: relative;
  width: 100%;
  height: 240px;
  padding: 10px 20px 16px;
  box-sizing: border-box;

  .top {
    .flex-b;
    margin-bottom: 16px;

    p {
      font-size: @size-12;
      color: @gray-3;
    }
  }

  .message {
    text-indent: 2em;
    height: 140px;
    line-height: 1.6;
    font-size: 15px;
    font-family: "qiantuxianmoti";
    color: @gray-1;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .foot {
    width: 100%;
    .flex-b;
    margin-top: 16px;

    .foot-left {
      width: 30%;
      .flex-b;

      .iconfont {
        color: @gray-2;
        font-size: @size-16;
        transform: @tr;
      }

      .icon-liuyan {
        font-size: 18px;
        margin-left: @padding-8;
      }

      .icon-aixin {
        &:hover {
          color: @like;
        }
      }

      .like {
        color: @like;
      }
    }

    .right-name {
      font-family: "qiantuxianmoti";
      font-weight: 500;
      font-size: @size-16;
    }
  }

  .wait-delete,
  .have-report {
    position: absolute;
    top: -4px;
    right: 24px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: @primary-color;
    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.3);
  }

  .have-report {
    right: 10px;
    background: @success-color;
  }
}
</style>
