<template>
  <transition name="viewer">
    <div class="sgy-viewer" v-if="isViewer" :style="occupied ? 'margin-top:0' : ''">
      <div class="viewer">
        <img v-if="!newsImg" :src="baseUrl + imgType + photos[activeIndex]" />
        <img v-if="newsImg" :src="baseUrl + imgType + newsImg" />
      </div>
      <div v-if="!newsImg" class="swiper sw-left" @click.stop="$emit('toggleViewer', 0)">
        <span class="iconfont icon-xiangzuo1"></span>
      </div>
      <div v-if="!newsImg" class="swiper sw-right" @click.stop="$emit('toggleViewer', 1)">
        <span class="iconfont icon-xiangyou1"></span>
      </div>
    </div>
  </transition>
</template>

<script>
import { baseUrl } from "@/utils/env";
export default {
  props: {
    photos: {
      default: [],
    },
    activeIndex: {
      default: 0,
    },
    isViewer: {
      default: false,
    },
    newsImg: {
      default: '',
    },
    occupied: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      baseUrl,
      imgType: '/photo/'
    };
  },
};
</script>
<style lang="less" scoped>
.sgy-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 60px;
  padding-right: 360px;
  box-sizing: border-box;
  background: @gray-10-8;
  background-size: 600vh 100vh;
  backdrop-filter: blur(30px);
  z-index: 88;

  .viewer {
    position: relative;

    &>img {
      min-width: calc((100vw - 360px - 220px) / 2);
      max-width: calc(100vw - 360px - 220px);
      min-height: 78vh;
      max-height: 85vh;
      object-fit: cover;
      margin: 50px auto 0;
    }
  }

  .swiper {
    position: absolute;
    top: 42%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: @gray-10-8;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 5px 1px rgba(69, 69, 69, 0.1);
    .flex;
    cursor: pointer;

    .iconfont {
      font-size: 32px;
      color: @gray-1;
    }
  }

  .sw-left {
    left: @padding-20;
  }

  .sw-right {
    right: calc(360px + @padding-20);
  }
}

.isViewer {

  // 入场动画
  &-enter {
    &-from {
      opacity: 0;
    }

    &-active {
      transition: all 0.2s ease-out;
    }

    &-to {
      opacity: 1;
    }
  }

  &-leave {
    &-from {
      opacity: 1;
    }

    &-active {
      transition: all 0.2s ease-in;
    }

    &-to {
      opacity: 0;
    }
  }
}
</style>
