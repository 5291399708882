/** 存储留言墙数据 */
export const setNotes = ( key, value ) => {
  if ( ( key || key === 0 ) && 'data' in value && 'page' in value ) {
    sessionStorage.setItem( 'noteData' + key, JSON.stringify( value.data ) );
    sessionStorage.setItem( 'notePage' + key, JSON.stringify( value.page ) );
  }
};
/** 存储照片墙数据 */
export const setPhotos = ( key, value ) => {
  if ( ( key || key === 0 ) && 'data' in value && 'page' in value ) {
    sessionStorage.setItem( 'photoData' + key, JSON.stringify( value.data ) );
    sessionStorage.setItem( 'photoPage' + key, JSON.stringify( value.page ) );
  }
};
/** 获取留言墙数据 */
export const getNotes = key => {
  if ( key || key === 0 ) {
    const data = JSON.parse( sessionStorage.getItem( 'noteData' + key ) );
    const page = JSON.parse( sessionStorage.getItem( 'notePage' + key ) );
    return { data, page };
  }
};
/** 获取照片墙数据 */
export const getPhotos = key => {
  if ( key || key === 0 ) {
    const data = JSON.parse( sessionStorage.getItem( 'photoData' + key ) );
    const page = JSON.parse( sessionStorage.getItem( 'photoPage' + key ) );
    return { data, page };
  }
};
/** 移除留言墙数据 */
export const removeNotes = key => {
  if ( key || key === 0 ) {
    sessionStorage.removeItem( 'noteData' + key );
    sessionStorage.removeItem( 'notePage' + key );
  }
};
/** 移除照片墙数据 */
export const removePhotos = key => {
  if ( key || key === 0 ) {
    sessionStorage.removeItem( 'photoData' + key );
    sessionStorage.removeItem( 'photoPage' + key );
  }
};


/** 获取胶囊数据 */
export const setCapsules = ( key, value ) => {
  if ( ( key || key === 0 ) && 'data' in value && 'page' in value ) {
    sessionStorage.setItem( 'capsuleData' + key, JSON.stringify( value.data ) );
    sessionStorage.setItem( 'capsulePage' + key, JSON.stringify( value.page ) );
  }
};
/** 获取胶囊数据 */
export const getCapsules = key => {
  if ( key || key === 0 ) {
    const data = JSON.parse( sessionStorage.getItem( 'capsuleData' + key ) );
    const page = JSON.parse( sessionStorage.getItem( 'capsulePage' + key ) );
    return { data, page };
  }
};


/** 获取房间数据 */
export const setRooms = ( key, value ) => {
  if ( ( key || key === 0 ) && 'data' in value && 'page' in value ) {
    sessionStorage.setItem( 'roomData' + key, JSON.stringify( value.data ) );
    sessionStorage.setItem( 'roomPage' + key, JSON.stringify( value.page ) );
  }
};
/** 获取房间数据 */
export const getRooms = key => {
  if ( key || key === 0 ) {
    const data = JSON.parse( sessionStorage.getItem( 'roomData' + key ) );
    const page = JSON.parse( sessionStorage.getItem( 'roomPage' + key ) );
    return { data, page };
  }
};


/** 获取个人留言 */
export const getMyNotes = key => {
  if ( key ) {
    const data = JSON.parse( sessionStorage.getItem( 'myNoteData' + key ) );
    const page = JSON.parse( sessionStorage.getItem( 'myNotePage' + key ) );
    return { data, page };
  }
};
/** 获取个人照片 */
export const getMyPhotos = key => {
  if ( key ) {
    const data = JSON.parse( sessionStorage.getItem( 'myPhotoData' + key ) );
    const page = JSON.parse( sessionStorage.getItem( 'myPhotoPage' + key ) );
    return { data, page };
  }
};
/** 获取个人邮件 */
export const getMyCapsules = key => {
  if ( key ) {
    const data = JSON.parse( sessionStorage.getItem( 'myCapsulesData' + key ) );
    const page = JSON.parse( sessionStorage.getItem( 'myCapsulesPage' + key ) );
    return { data, page };
  }
};
/** 获取个人房间 */
export const getMyRooms = key => {
  if ( key ) {
    const data = JSON.parse( sessionStorage.getItem( 'myRoomsData' + key ) );
    const page = JSON.parse( sessionStorage.getItem( 'myRoomsPage' + key ) );
    return { data, page };
  }
};
/** 存储个人留言 */
export const setMyNotes = ( key, value ) => {
  if ( key && 'data' in value && 'page' in value ) {
    sessionStorage.setItem( 'myNoteData' + key, JSON.stringify( value.data ) );
    sessionStorage.setItem( 'myNotePage' + key, JSON.stringify( value.page ) );
  }
};
/** 存储个人照片 */
export const setMyPhotos = ( key, value ) => {
  if ( key && 'data' in value && 'page' in value ) {
    sessionStorage.setItem( 'myPhotoData' + key, JSON.stringify( value.data ) );
    sessionStorage.setItem( 'myPhotoPage' + key, JSON.stringify( value.page ) );
  }
};
/** 存储个人邮件 */
export const setMyCapsules = ( key, value ) => {
  if ( key && 'data' in value && 'page' in value ) {
    sessionStorage.setItem( 'myCapsulesData' + key, JSON.stringify( value.data ) );
    sessionStorage.setItem( 'myCapsulesPage' + key, JSON.stringify( value.page ) );
  }
};
/** 存储个人房间 */
export const setMyRooms = ( key, value ) => {
  if ( key && 'data' in value && 'page' in value ) {
    sessionStorage.setItem( 'myRoomsData' + key, JSON.stringify( value.data ) );
    sessionStorage.setItem( 'myRoomsPage' + key, JSON.stringify( value.page ) );
  }
};
/** 退出时清除所有缓存数据 */
export const removeData = () => {
  sessionStorage.clear();
};