import Message from './message/message.js';
import ShowDialog from './ShowDialog/ShowDialog.js';

/**
 * 注释：定义了一个默认导出的对象
 */
export default {
  /**
   * 注释：install方法，在该方法中将Message对象赋值给app.config.globalProperties.$message
   * @param {Object} app - 参数说明
   */
  install ( app ) {
    app.config.globalProperties.$message = Message;
    app.config.globalProperties.$showDialog = ShowDialog;
  }
};