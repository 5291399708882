const listeners = {};

export default {
  $on ( event, callback ) {
    if ( !listeners[event] ) {
      listeners[event] = [];
    }
    listeners[event].push( callback );
  },
  $emit ( event, ...arg ) {
    if ( listeners[event] ) {
      listeners[event].forEach( callback => {
        callback( ...arg );
      } );
    }
  }
};