<template>
  <div class="foot-friendly-link" :style="{ 'padding': mobile && 0 }">
    <div class="link-title" v-if="!mobile">友情链接</div>
    <div class="link-box" v-if="friendlyLinks.length > 0">
      <template v-for="(  item, index  ) in   friendlyLinks  " :key="item.name">
        <div class="link-text" v-if="active === index" @click="handleLink(item.link)">
          <img v-if="item.image != ''" :src="item.image" :alt="item.name" class="link-img">
          <div class="link-name">{{ item.title }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default { name: 'FriendlyLink' };
</script>
<script setup>
import { friendlyLinks } from "@/utils/data.js";
import { ref, defineProps } from 'vue';

const active = ref( 0 );
defineProps( { mobile: { type: Boolean, default: false } } );
let oldDate = '';

const handleLink = ( link ) => {
  if ( window.plus ) {
    window.plus.runtime.openURL( link );
  } else {
    window.open( link, '_blank' );
  }
};

const activeAdd = () => {
  if ( oldDate === '' || ( new Date().getTime() - oldDate ) > 5000 ) {
    active.value = active.value + 1 > friendlyLinks.length - 1 ? 0 : active.value + 1;
    oldDate = new Date().getTime();
  }
  requestAnimationFrame( activeAdd );
};
// 使用requestAnimationFrame函数 递归调用，比定时器节省性能
requestAnimationFrame( activeAdd );

</script>
<style lang="less" scoped>
.foot-friendly-link {
  width: 100%;
  margin: 0 auto;
  padding: 20px 30px 0 30px;
  box-sizing: border-box;
  .flex-s;

  .link-title {
    max-width: 80px;
    display: inline-block;
    font-size: 18px;
  }

  .link-box {
    max-width: 60%;
    display: inline-block;
    margin-left: 20px;

    .link-text {
      .flex-s;
      gap: 6px;

      .link-img {
        width: 30px;
        overflow: hidden;
      }

      .link-name {
        width: 60vw;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>