<template>
  <button class="sgy-button" :class="[
    medium ? 'medium' : '',
    large ? 'large' : '',
    small ? 'small' : '',
    flat ? 'flat' : '',
    line ? 'line' : '',
    _flat ? '_flat' : '',
    _line ? '_line' : '',
    notAollwed ? 'notAollwed' : '',
  ]">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    large: {
      // 大
      type: Boolean,
      default: false,
    },
    medium: {
      // 中
      type: Boolean,
      default: false,
    },
    small: {
      // 小
      type: Boolean,
      default: false,
    },
    flat: {
      // 面型
      type: Boolean,
      default: false,
    },
    line: {
      // 线型
      type: Boolean,
      default: false,
    },
    _flat: {
      // 面型 - 强调
      type: Boolean,
      default: false,
    },
    _line: {
      // 线型 - 强调
      type: Boolean,
      default: false,
    },
    notAollwed: {
      // 禁用
      type: Boolean,
      default: false,
    }
  },
};
</script>
<style lang="less" scoped>
.sgy-button {
  border: none;
  background: none;
  text-align: center;
  border-radius: 24px;
  cursor: pointer;
  transition: @tr;
}

.large {
  min-width: 100px;
  height: 48px;
  padding: 0 24px;
}

.medium {
  min-width: 80px;
  height: 36px;
  padding: 0 20px;
}

.small {
  min-width: 72px;
  height: 32px;
  padding: 0 20px;
}

.flat {
  background: @gray-1;
  color: @gray-10;
}

.line {
  border: 1px solid @gray-1;
  color: @gray-1;
}

._flat {
  background: @primary-color;
  color: @gray-10;
  font-weight: 600;
}

._line {
  color: @gray-1;
}

.line:hover {
  background: #ededf1;
}

.flat:hover {
  background: #00000080;
}

.notAollwed {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
