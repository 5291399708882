<template>
  <router-view></router-view>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

// 监听窗口是否处于活跃
const setInActiveStatus = () => {
  if ( document.visibilityState === 'visible' ) {
    store.commit( 'setActiveStatus', true ); // 前台
  } else {
    store.commit( 'setActiveStatus', false ); // 后台
  }
};

// 监听窗口大小变化
const setPlatform = () => {
  if ( document.documentElement.clientWidth > 450 ) {
    store.commit( "setIsMobile", false ); // PC
  } else {
    store.commit( "setIsMobile", true ); // H5
  }
};

// 粘贴内容为文件对象都禁止粘贴
const noPasteFile = ( e ) => {
  let data = e.clipboardData.items[0].getAsFile();
  if ( data ) e.preventDefault();
};

// 钩子
onMounted( () => {
  setPlatform();
  setInActiveStatus();
  window.addEventListener( "resize", setPlatform );
  window.addEventListener( 'paste', noPasteFile );
  document.addEventListener( 'visibilitychange', setInActiveStatus );
} );

onUnmounted( () => {
  // 移除监听
  window.removeEventListener( "resize", setPlatform );
  window.removeEventListener( 'paste', noPasteFile );
  document.removeEventListener( 'visibilitychange', setInActiveStatus );
} );
</script>

<style lang="less">
@import "./assets/fonts/icon/iconfont.css";
@import "./assets/fonts/Hanzipen/index.less";
@import "quill/dist/quill.core.css";

#app {
  height: 100%;
}

::-webkit-scrollbar {
  width: 3px;
  /* 设置滚动条宽度 */
}

::-webkit-scrollbar-thumb {
  background: @gray-3;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块圆角 */
}

.use {
  background-color: @success-color;
}

.stop {
  background-color: @warning-color;
}
</style>