import Vuex from 'vuex';
import createVuexPersistedState from 'vuex-persistedstate';
export default new Vuex.Store( {
  plugins: [
    createVuexPersistedState( {
      key: 'vuex',
      storage: window.localStorage,
    } ),
  ],

  state: {
    user: null,
    ip: '',
    theme: false,
    isMobile: false,
    safeArea: { top: 0, bottom: 0 },
    activeStatus: true, // 是否处于活跃
    isNotifyEnv: true, // 是否支持通知环境
  },
  mutations: {
    setUser ( state, user ) {
      state.user = user;
    },
    setIp ( state, ip ) {
      state.ip = ip;
    },
    setTheme ( state, theme ) {
      state.theme = theme;
    },
    setIsMobile ( state, isMobile ) {
      state.isMobile = isMobile;
    },
    setSafeArea ( state, safeArea ) {
      state.safeArea = safeArea;
    },
    setActiveStatus ( state, activeStatus ) {
      state.activeStatus = activeStatus;
    },
    setIsNotifyEnv ( state, isNotifyEnv ) {
      state.isNotifyEnv = isNotifyEnv;
    }
  },
} );