<template>
  <transition name="down">
    <div class="dialog-container">
      <div class="dialog" :class="type">
        <div class="title" v-if="props.title">{{ props.title }}</div>
        <div class="message" v-if="props.message" v-html="message"></div>
        <input class="input" type="text" autofocus :placeholder="input" v-if="props.input" v-model="inputValue" />
        <div class="img-box" v-if="props.currentType === 'Tipping'">
          <img class="img" src="../../../assets/images/WX.jpg" alt="weiXin">
          <img class="img" src="../../../assets/images/ZFB.jpg" alt="zhiFuBao">
        </div>
        <div class="button">
          <SGYButton medium line v-if="props.cancel" @click="handleCannel">取消</SGYButton>
          <SGYButton medium _flat v-if="props.confirm" @click="handleConfirm">确定</SGYButton>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default { name: 'SGYShowDialog' };
</script>
<script setup>
import SGYButton from '@/components/SGYButton.vue';
import { defineProps, defineEmits, ref } from 'vue';

const emit = defineEmits( ['cancel', 'confirm'] );
const props = defineProps( {
  currentType: { default: null, type: String },
  type: { default: 'dSmall', type: String },
  title: { default: null, type: String },
  message: { default: null, type: String },
  input: { default: null, type: String },
  cancel: { default: false, type: Boolean },
  confirm: { default: false, type: Boolean },
} );

const inputValue = ref( '' );

const handleCannel = () => {
  emit( 'cancel' );
};

const handleConfirm = () => {
  emit( 'confirm', inputValue.value );
}

</script>
<style lang="less" scoped>
.down {
  &-enter {
    &-from {
      opacity: 0;
      transform: translateY(-50px);
    }

    &-active {
      transition: @tr;
    }

    &-to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-leave {
    &-from {
      opacity: 1;
      transform: translateY(0);
    }

    &-active {
      transition: all @tr;
    }

    &-to {
      opacity: 0;
      transform: translateY(50px);
    }
  }
}

.dLarge {
  width: 600px;
}

.dSmall {
  width: 360px;
}

.dialog-container {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: @gray-9-4;
  backdrop-filter: blur(10px);
}

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  padding: @padding-20;
  background-color: @gray-10-8;
  backdrop-filter: blur(10px);
  border: 1px solid @gray-10;
  border-radius: 20px;
  box-shadow: 2px 2px 15px 0 @gray-1-2;

  .button {
    padding-top: 20px;
    .flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .title {
    text-align: center;
    font-size: 20px;
  }

  .message {
    text-align: center;
    width: 100%;
    font-size: 18px;
    margin: 20px auto;
  }

  .input {
    width: 80%;
    margin: 20px auto;
    padding: 10px 12px;
    text-align: center;
    font-size: 18px;
    .flex;
    border: none;
    border-bottom: 1px solid @gray-1-6;
    background-color: transparent;
  }

  .img-box {
    .flex;
    gap: 10px;

    .img {
      width: 38vw;
      height: 38vw;
    }
  }
}
</style>