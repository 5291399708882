import { getTokenPayload } from '../utils/handleFn';
import store from '../store';

/** 获取短token */
export const getAccessToken = () => {
  return localStorage.getItem( 'accessToken' );
};

/** 获取长token */
export const getRefreshToken = () => {
  return localStorage.getItem( 'refreshToken' );
};

/** 设置短token */
export const setAccessToken = ( value ) => {
  store.state.user = getTokenPayload( value );
  localStorage.setItem( 'accessToken', value );
};

/** 设置长token */
export const setRefreshToken = ( value ) => {
  localStorage.setItem( 'refreshToken', value );
};

/** 清除短token */
export const removeAccessToken = () => {
  store.state.user = null;
  localStorage.removeItem( 'accessToken' );
};

/** 清除长token */
export const removeRefreshToken = () => {
  localStorage.removeItem( 'refreshToken' );
};