<template>
  <div class="channel-detail-container">
    <div class="content">

      <div class="left-info" v-if="!isMobile && !isHall">
        <div class="module">
          <div class="label">房间成员</div>
          <div class="list">
            <MemberDetailVue :memberInfo="item" :memberActiveId="memberActiveId" :top="top" :left="left"
              :isRoomAdmin="isRoomAdmin" v-for="item in memberData" :key="item.id"
              :roomUserId="Number(chatroomInfo.userId)" @click="showMemberInfo($event, item.id)"
              @close="memberActiveId = -1" @kickOut="onKickOutMember(item.userId)" @silenceClient="onSilenceMember">
            </MemberDetailVue>
          </div>
        </div>
        <div class="module">
          <div class="label">房间简介</div>
          <div class="message">{{ chatroomInfo.message }}</div>
        </div>
      </div>

      <main class="main">
        <div class="nav">
          <div class="chatroom-title">{{ chatroomInfo.title }}</div>
          <div class="icon-box">
            <i class="iconfont icon-guanbi" title="离开房间" @click="onCloseRoom()"></i>
            <i class="iconfont icon-tuichu" title="退出房间" v-if="!isRoomAdmin && !isHall" @click="onLeaveRoom()"></i>
            <i class="iconfont icon-caidan" title="房间信息" @click="isModal = true"></i>
          </div>
        </div>
        <div class="interlace-box" ref="interlaceBox">
          <div class="refresh-box" :class="messageRefreshStatus && 'refreshing'">
            <span class="iconfont icon-shuaxin1" v-if="messageRefreshStatus"></span>
          </div>
          <div class="aInfo" v-for="(item, index) in messageData" :key="index">
            <div class="msgTime" v-if="timeIntervalHandle(index)">{{ timeIntervalHandle(index) }}</div>
            <div class="aInfo-message-box" :class="item.userId == uid ? 'right' : 'left'">
              <div class="avatar" v-if="item.avatar != ''"><img class="a-img" :src="item.avatar"></div>
              <div class="avatar" v-else>{{ item.name }}</div>
              <div class="message">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="info-box">
          <textarea autofocus maxlength="500" class="info-input" v-model="sendMessage" placeholder="发送内容...">
          </textarea>
          <div class="info-submit" @click="onSendMessage">发送</div>
        </div>
      </main>

    </div>
  </div>
  <RoomFormVue v-if="isModal" :chatroomInfo="chatroomInfo" :isHall="isHall" @close="onCloseModal" @load="onLoad"
    :memberData="memberData" @kickClient="onKickClient" @mobileSilenceClient="onSilenceClientSocket"></RoomFormVue>
</template>

<script>
export default { name: 'ChannelMessageDetail' };
</script>
<script setup>
import RoomFormVue from '@/components/RoomForm.vue';
import Message from '@/components/SGY/message/message';
import MemberDetailVue from '@/components/MemberDetail.vue';
import showDialog from '@/components/SGY/ShowDialog/ShowDialog';
import { findRoomsApi, deleteRoomMembersApi, findRoomMembersApi, findRoomMessagesApi, findHallRoomsApi, updateRoomMembersStatusApi } from '@/api/index';
import eventBus from '@/utils/eventBus';
import { onMounted, onUnmounted, ref, computed, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { SocketService } from '@/api/socket';

const store = useStore();
const router = useRouter();
const route = useRoute();
const isModal = ref( false ); // 弹窗
const memberData = ref( [] ); // 群成员数据
const memberActiveId = ref( -1 ); // 激活的成员id
const top = ref( 0 ); // 成员弹窗位置
const left = ref( 0 ); // 成员弹窗位置
const chatroomInfo = ref( {} ); // 聊天室基本信息
const sendMessage = ref( '' ); // 发送信息
const messageData = ref( [] ); // 群消息数据
const interlaceBox = ref( null ); // 聊天室盒子
const lastMessageBoxHeight = ref(); // 上次消息盒子的高度
const messageRefreshStatus = ref( false ); // 刷新消息状态
const isLoad = ref( false ); // 返回上一页是否需要刷新
const lastOperationTime = ref( {} ); // 上一次请求消息的时间
const memberParamsData = ref( {
  roomId: '',
  page: 1,
  pagesize: 1000
} );
const messageParamsData = ref( {
  roomId: '',
  page: 1,
  pagesize: 30
} );
const timeInterval = 1000 * 60 * 3; // 3分钟

const roomNumber = computed( () => route.params && route.params.roomNumber );
const isMobile = computed( () => store.state.isMobile );
const uid = computed( () => Number( store.state.user?.id ) );
const isRoomAdmin = computed( () => uid.value === Number( chatroomInfo.value?.userId ) );
const isHall = computed( () => Number( roomNumber.value ) === 1 );

// 接收消息 - 必须写在连接socket之前
const receiveMessage = ( data ) => {
  messageData.value.push( {
    roomId: data.roomId || 0,
    userId: data.user?.id || 0,
    avatar: data.user?.avatar || '',
    name: data.user?.nickname || '',
    content: data.message,
  } );
  scrollToNowMessage();
};

// 连接socket
const service = new SocketService( roomNumber.value, store.state.user, receiveMessage );
service.auth();
// service.joinRoom();

// 发送消息
const onSendMessage = () => {
  let flag = getMemberStatus();
  if ( !flag ) {
    Message( { message: '您已被禁言，无法发言', type: 'warning' } );
    return false;
  }
  if ( sendMessage.value.trim() !== '' ) {
    service.sendMessage( chatroomInfo.value.id, sendMessage.value );
    messageData.value.push( {
      roomId: chatroomInfo.value.id || 0,
      userId: uid.value || 0,
      avatar: store.state.user?.avatar || '',
      name: store.state.user?.nickname || '',
      content: sendMessage.value,
    } );
    sendMessage.value = '';
    scrollToNowMessage();
  }
};

// 离开房间
const onCloseRoom = () => {
  service.leaveRoom();
  service.dispose();
  if ( router.options.history.state.back.includes( '/my' ) && isLoad.value ) {
    router.replace( `/my/${ 4 }?r=${ isLoad.value }` );
  } else {
    router.back();
  }
};

// 退出房间
const onLeaveRoom = async () => {
  showDialog( { title: '退出房间', message: '确认后将清除房间密钥？', cancel: true, onConfirm: deleteRoomMembersFn } );
};

// 获取房间用户列表
const findRoomMembersFn = async () => {
  if ( !throttle( 1000, 'roomMember' ) ) return;
  memberParamsData.value.roomId = chatroomInfo.value?.id;
  if ( !memberParamsData.value.roomId ) return;
  try {
    let res = await findRoomMembersApi( memberParamsData.value );
    if ( res.code === 200 ) {
      memberData.value = [];
      memberData.value = res.message;
    }
  } catch ( error ) {
    console.log( error );
  }
};

// 从房间中删除用户
const deleteRoomMembersFn = async () => {
  try {
    let res = await deleteRoomMembersApi( {
      roomId: chatroomInfo.value.id, userId: uid.value || 0,
    } );
    if ( res.code === 200 ) {
      Message( { message: '房间退出成功', type: 'success' } );
    }
    onCloseRoom();
  } catch ( error ) {
    console.log( error );
  }
};

// 获取房间信息
const findRoomsFn = async () => {
  if ( !throttle( 1000, 'roomInfo' ) ) return;
  let res;
  try {
    if ( isHall.value ) {
      res = await findHallRoomsApi();
    } else {
      res = await findRoomsApi( { 'roomNumber': roomNumber.value } );
    }
    if ( Number( res.code ) === 200 ) {
      chatroomInfo.value = [];
      chatroomInfo.value = res.message[0];
      !isHall.value && findRoomMembersFn();
      findRoomMessagesFn();
    }
  } catch ( err ) {
    console.log( err );
    Message( { message: '房间信息有误', type: 'error' } );
  }
};

// 显示用户信息
const showMemberInfo = ( e, id ) => {
  if ( memberActiveId.value != id ) {
    memberActiveId.value = id;
    top.value = e.clientY - e.target.offsetHeight;
    left.value = e.clientX;
  } else {
    memberActiveId.value = -1;
  }
};

// 踢人 - 数据库中删除该用户
const onKickOutMember = async ( userId ) => {
  try {
    let res = await deleteRoomMembersApi( { userId: userId, roomId: chatroomInfo.value.id } );
    if ( res.code === 200 ) {
      Message( { message: '删除成功', type: 'success' } );
      findRoomMembersFn();
      onKickClient( userId );
    }
  } catch ( error ) {
    Message( { message: '删除失败，请重试', type: 'error' } );
  }
};

// 踢人 - socket断开该用户的连接
const onKickClient = ( userId ) => {
  service.kickClient( userId );
};

// 禁言
const onSilenceMember = async ( userId, status ) => {
  status = status == 1 ? 0 : 1; // 修改禁言状态
  try {
    let res = await updateRoomMembersStatusApi( { userId: userId, roomId: chatroomInfo.value.id, status: status } );
    onSilenceClientSocket( userId, status );
    Message( { message: res.message, type: 'success' } );
  } catch ( error ) {
    console.log( error );
    Message( { message: '禁言失败，请刷新重试', type: 'error' } );
  }
};

// 禁言-socket 通知该用户
const onSilenceClientSocket = ( userId, status ) => {
  findRoomMembersFn();
  service.silenceClient( userId, status );
};

// 获取后30条消息数据
const findRoomMessagesFn = async () => {
  if ( !throttle( 300, 'roomMessage' ) ) return;
  if ( Number( messageParamsData.value.page ) > 0 ) {
    messageParamsData.value.roomId = chatroomInfo.value?.id;
    try {
      let res = await findRoomMessagesApi( messageParamsData.value );
      if ( res.code === 200 ) {
        messageRefreshStatus.value = false;
        if ( Number( messageParamsData.value.page ) === 1 ) {
          let messageDataReverse = res.message.reverse();
          messageData.value = [];
          messageData.value = messageDataReverse;
          scrollToNowMessage();
        } else {
          res.message.forEach( item => {
            messageData.value.unshift( item );
          } );
          scrollToNowMessage( 1 );
        }
        res.message.length < messageParamsData.value.pagesize ? messageParamsData.value.page = 0 : messageParamsData.value.page++;
      }
    } catch ( error ) {
      console.log( error );
    }
  }
};

// 消息间隔时间处理
const timeIntervalHandle = ( index ) => {
  if ( index > 0 ) {
    let interval = new Date( messageData.value[index].createTime ) - new Date( messageData.value[index - 1].createTime );
    if ( interval > timeInterval ) {
      return messageData.value[index].createTime.slice( 0, -3 );
    } else {
      return false;
    }
  }
};

// 向上滚动获取消息
const scrollGetMessage = ( e ) => {
  if ( e && e.target.nodeType === 1 ) {
    if ( e.target.scrollTop < 50 ) {
      if ( Number( messageParamsData.value.page ) > 0 ) {
        messageRefreshStatus.value = true;
        setTimeout( () => findRoomMessagesFn(), 100 );
      }
    }
  }
};

const throttle = ( timeInterval, type ) => {
  const now = Date.now();
  const lastCall = lastOperationTime.value[type] || 0;
  if ( now - lastCall < timeInterval ) {
    return false;
  } else {
    lastOperationTime.value[type] = now; // 更新上次调用时间  
    return true;
  }
};

// 默认滚动到底部
const scrollToNowMessage = async ( isJumpType ) => {
  await nextTick();
  if ( isJumpType && Number( isJumpType ) === 1 ) {
    interlaceBox.value.scrollTo( {
      top: interlaceBox.value.scrollHeight - lastMessageBoxHeight.value,
      behavior: 'instant'
    } );
  } else {
    interlaceBox.value.scrollTo( {
      top: interlaceBox.value.scrollHeight - interlaceBox.value.clientHeight,
      behavior: 'instant'
    } );
  }
  lastMessageBoxHeight.value = interlaceBox.value.scrollHeight;
  // console.log( '默认滚动距离：', interlaceBox.value.scrollHeight - interlaceBox.value.clientHeight );
};

// 获取用户状态
const getMemberStatus = () => {
  if ( memberData.value.length > 0 ) {
    return memberData.value.some( item => {
      // console.log( Number( item.userId ), uid.value );
      if ( Number( item.userId ) === uid.value ) {
        return Number( item.status ) === 0;
      } else {
        return true;
      }
    } );
  } else {
    return true;
  }
};

// 关闭弹窗
const onCloseModal = () => {
  isModal.value = false;
};

// 加载-关闭弹窗
const onLoad = () => {
  findRoomsFn();
  isModal.value = false;
  isLoad.value = true;
};

findRoomsFn();

onMounted( () => {
  document.body.style.overflowY = 'hidden';
  interlaceBox.value.addEventListener( 'scroll', scrollGetMessage );
  eventBus.$on( 'deleteRoom', ( deleteRoomId ) => service.deleteRoom( deleteRoomId ) ); // 调用socket.js中的deleteRoom方法
  eventBus.$on( 'memberRefresh', () => findRoomMembersFn() ); // 刷新用户数据
} );
onUnmounted( () => {
  document.body.style.overflowY = 'auto';
  interlaceBox.value && interlaceBox.value.removeEventListener( 'scroll', scrollGetMessage );
  service.leaveRoom();
  service.dispose();
} );
</script>
<style lang="less" scoped>
.channel-detail-container {
  position: relative;
  min-height: 88%;
  text-align: center;
  background-color: @gray-10-2;
  backdrop-filter: blur(10px);

  .content {
    width: 88vw;
    height: 88vh;
    margin: 0 auto;
    border: 1px solid @gray-10-8;
    background-color: @gray-10-6;
    position: relative;
    top: 2vh;
    .flex-b;

    .left-info {
      width: 35%;
      height: 100%;
      padding: 30px;

      .module {
        margin-bottom: 20px;
        max-height: 48%;
        .flex-s;
        flex-direction: column;

        .label {
          font-size: 18px;
          width: 100%;
          border-bottom: 1px solid @gray-2;
        }

        .message {
          padding: 2px;
          position: relative;
          width: 100%;
          max-height: 60%;
          text-indent: 2em;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 15;
          -webkit-box-orient: vertical;
        }

        .list {
          padding: 5px;
          width: 100%;
          height: calc(100% - 30px);
          overflow: auto;
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(50px, 5fr));
          justify-content: center;
          grid-gap: 5px;
        }
      }
    }

    .main {
      flex: 1;
      height: 100%;
      background-color: @gray-3-2;
      position: relative;

      .interlace-box {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 50px 20px 60px;
        .flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 15px;

        .aInfo {
          width: 100%;

          .msgTime {
            width: 100%;
            font-size: 12px;
            color: @gray-3;
            text-align: center;
            margin-bottom: 12px;
          }

          .aInfo-message-box {
            position: relative;
            max-width: 85%;
            .flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;

            .avatar {
              width: 42px;
              height: 42px;
              line-height: 42px;
              text-align: center;
              border-radius: 50%;
              font-size: 18px;

              .a-img {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                object-fit: cover;
                overflow: hidden;
              }
            }

            .message {
              max-width: 100%;
              text-align: left;
              padding: 11px;
              background-color: @gray-10;
              border-radius: 5px;
              letter-spacing: 1px;
              overflow-wrap: anywhere;
              position: relative;

              &::after {
                content: '';
                border: 5px solid @gray-10;
                position: absolute;
                top: 15px;
                transform: rotateZ(45deg);
              }
            }
          }

          .left {
            margin-right: auto;

            .avatar {
              background-color: @success-color;
            }

            .message {
              &::after {
                left: -4px;
                border-color: transparent transparent @gray-10 @gray-10;
              }
            }
          }

          .right {
            margin-left: auto;
            flex-direction: row-reverse;

            .avatar {
              background-color: @primary-color;
            }

            .message {
              &::after {
                right: -4px;
                border-color: @gray-10 @gray-10 transparent transparent;
              }
            }
          }
        }

        .refresh-box {
          display: inline-block;
          width: auto;
          font-size: 14px;
          color: @gray-3;
          transition: @tr;
          .flex;

          .icon-shuaxin1 {
            font-size: 18px;
          }

        }

        .refreshing {
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      .info-box {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: @gray-10;
        font-size: 14px;
        .flex;

        .info-input {
          width: 80%;
          height: 45px;
          text-align: left;
          vertical-align: bottom;
          padding: 12px 20px;
          outline: none;
          resize: none;
        }

        .info-submit {
          flex: 20%;
          height: 45px;
          width: 100px;
          background-color: @primary-color;
          color: @gray-10;
          cursor: pointer;
          .flex;
        }
      }

      .nav {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 20px;
        width: 100%;
        height: 45px;
        overflow: hidden;
        background-color: @gray-10;
        cursor: pointer;
        z-index: 1;
        .flex-b;

        .chatroom-title {
          font-size: 18px;
        }

        .icon-box {
          .flex;
          gap: 16px;

          .icon-caidan {
            font-size: 24px;
          }

          .icon-tuichu {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>