import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";
import { getAccessToken } from "../store/localStorage";
import Message from "@/components/SGY/message/message";
import Wall from '../views/WallMessage/WallMessage.vue';
import Email from '../views/EmailMessage/EmailMessage.vue';
import Channel from '../views/ChannelMessage/ChannelMessage.vue';
import My from '../views/MyMessage/MyMessage.vue';
import ChannelMessageDetail from '../views/ChannelMessage/ChannelMessageDetail.vue';
import LoginPc from "../views/Login/LoginPc.vue";
import LoginH5 from "../views/Login/LoginH5.vue";

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import( '../views/SGYindex.vue' ),
        redirect: '/wall?id=0',
        children: [
            {
                path: '/wall',
                name: 'WallMessage',
                component: Wall,
                meta: {
                    title: '留言',
                }
            },
            {
                path: '/email',
                name: 'EmailMessage',
                component: Email,
                meta: {
                    title: '胶囊',
                }
            },
            {
                path: '/channel',
                name: 'ChannelMessage',
                component: Channel,
                meta: {
                    title: '频道',
                },
                children: [{
                    path: '/channelDetail/:roomNumber',
                    name: 'ChannelMessageDetail',
                    component: ChannelMessageDetail,
                    meta: {
                        title: '聊天室',
                    }
                }]
            },
            {
                path: '/my/:flag',
                name: 'MyMessage',
                component: My,
                meta: {
                    title: '我的',
                }
            },
        ]
    },
    {
        path: '/loginPc',
        name: 'loginPc',
        component: LoginPc,
        meta: {
            type: 'login',
        }
    },
    {
        path: '/loginH5',
        name: 'loginH5',
        component: LoginH5,
        meta: {
            type: 'login',
        }
    },
];


const router = createRouter( {
    history: createWebHashHistory(),
    routes,
    scrollBehavior ( to ) {
        // return 期望滚动到哪个的位置
        if ( to.path === "/" || to.path === "/wall" || to.path === "/email" || to.path === "/channel" || to.path === "/my" ) {
            return { top: 0 };
        }
    }
} );

router.beforeEach( ( to, from, next ) => {
    if ( to.path === "/email" || to.path === "/channel" || to.path === "/my" ) {
        if ( getAccessToken() ) {
            next();
        } else {
            Message( { message: "请先登录", type: "warning" } );
            store.state.isMobile ? next( "/loginH5" ) : next( "/loginPc" );
        }
    } else {
        next();
    }
} );

export default router;