import { baseUrl } from '@/utils/env.js';

function isDesktop () {
  const userAgent = navigator.userAgent.toLowerCase();
  return !( /mobile|android|iphone|ipad|phone/i.test( userAgent ) );
}

export function bug () {
  // 防右键
  if ( isDesktop() ) {
    document.oncontextmenu = new Function( "return false;" );
  }
  // 防控制台
  document.onkeydown = document.onkeyup = document.onkeypress = function ( event ) {
    var e = event || window.event || arguments.callee.caller.arguments[0];
    if ( e && e.keyCode == 123 && ( baseUrl === 'http://www.1.lxlike.top' || baseUrl === 'https://www.1.lxlike.top' ) ) {
      window.location = 'about: blank';
      e.returnValue = false;
      return false;
    }
  };
  // 放保存
  document.onkeydown = function ( e ) {
    if ( ( e.ctrlKey ) && ( e.keyCode == 83 ) || ( e.ctrlKey ) && ( e.code == 83 ) ) return false;
  };
}