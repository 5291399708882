<template>
  <div class="card-detail" @click.stop>
    <div class="top-btn" v-if="!isAdmin && !isNews && !isMy">
      <p class="revoke" @click="onDeleteNote">联系墙主撕掉该便签</p>
      <p class="report" @click="onReport">举报</p>
    </div>
    <div class="top-btn" v-if="(isAdmin || isMy) && !isNews">
      <p class="report" @click="isDrawer = !isDrawer">撕掉便签</p>
      <p class="revoke" v-show="isDrawer" @click="onAdminDeleteNote">确认</p>
    </div>
    <img v-if="isMobile && cardAndPhoto.imgurl" :src="baseUrl + imgType + cardAndPhoto.imgurl" class="imgBox">
    <NoteCard width="100%" class="card-main" :note="cardAndPhoto" :isNews="isNews"></NoteCard>
    <div class="form" v-if="!isNews">
      <textarea class="message" placeholder="评论..." v-model="commentText"></textarea>
      <div class="button">
        <input type="text" class="author" placeholder="签名" :disabled="isNickNameEdit" v-model="author" />
        <SGYButton medium flat :notAollwed="!isDisabeld" @click="onSubmitComment">确定</SGYButton>
      </div>
    </div>
    <div class="title comment-big-title">
      评论<span class="count" v-if="cardAndPhoto.comcount[0].count > 0">
        {{ cardAndPhoto.comcount[0].count }}</span>
    </div>
    <div class="comment" v-if="cardAndPhoto.comcount[0].count > 0">
      <div class="comment-item" v-for="item in comment" :key="item.id" @click="openDeleteComment(item)">
        <div class="avatar" :style="{ background: portrait[item.imgurl] }">
          <img class="img" v-if="item.imgurl.length > 3" :src="item.imgurl">
        </div>
        <div class="comment-main">
          <div class="comment-head">
            <div class="name">{{ item.name }}</div>
            <div class="time">{{ formatDate(item.moment) }}</div>
          </div>
          <div class="comment-body">{{ item.comment }}</div>
          <div class="delete-comment" v-show="(isAdmin || isMy || isMySend) && (isDeleteComment == item.id)"
            @click="onDeleteCommentData(item.id)">
            删除</div>
        </div>
      </div>
      <div class="load-down" @click="getCommentData" v-if="pageNum > 0">加载更多^_^ </div>
    </div>
    <div v-if="cardAndPhoto.comcount[0].count == 0" class="no-comment">
      <img src="../assets/images/noComment.png" alt="暂无评论" />
    </div>
  </div>
</template>

<script>
import NoteCard from "./NoteCard.vue";
import SGYButton from "./SGYButton.vue";
import { portrait } from "../utils/data.js";
import { formatDate } from "../utils/handleFn.js";
import { baseUrl } from "@/utils/env";
import { insertCommentApi, findCommentPageApi, insertFeedbackApi, deleteWallApi, deleteCommentApi } from "@/api/index";
export default {
  components: {
    NoteCard,
    SGYButton,
  },
  props: {
    card: {
      details: {},
    },
    photo: {
      details: {},
    },
    news: {
      details: {},
    },
  },
  data () {
    return {
      comment: [],
      portrait,
      formatDate,
      commentText: "",
      author: this.$store.state.user?.nickname || '匿名',
      isNickNameEdit: this.$store.state.user?.nickname != null, // 是否修改昵称
      userId: this.$store.state.user?.id || this.$store.state.ip,
      pageNum: 1,
      pageSize: 10,
      routerType: null, // 墙类型
      isAdmin: this.$store.state.user?.competence == 0, // 用户是否管理员
      isDrawer: false, // 确认删除的弹出抽屉
      isDeleteComment: false, // 确认删除评论
      baseUrl, // 图片地址
      imgType: '/photo/',
      isMySend: false, // 是否为我发送
    };
  },
  computed: {
    /** 是否禁用确认 */
    isDisabeld () {
      if ( this.commentText && this.commentText.trim() != "" ) {
        return true;
      } else {
        return false;
      }
    },
    isNews () {
      return this.news && 'type' in this.news;
    },
    isMobile () {
      return this.$store.state && this.$store.state.isMobile;
    },
    isMy () {
      return Number( this.cardAndPhoto.userId ) === Number( this.userId );
    },
    /** 卡片数据 */
    cardAndPhoto () {
      if ( this.news && 'type' in this.news ) {
        return this.news;
      } else {
        return this.card ? this.card : this.photo;
      }
    },
  },
  watch: {
    "cardAndPhoto.id" () {
      this.pageNum = 1;
      this.getCommentData();
    },
  },
  mounted () {
    this.getCommentData();
    document.body.style.overflowY = 'hidden';
  },
  unmounted () {
    document.body.style.overflowY = 'auto';
  },
  methods: {
    /**评论 */
    async onSubmitComment () {
      let randomImg;
      if ( this.$store.state.user ) {
        randomImg = this.$store.state.user.avatar;
      } else {
        randomImg = Math.floor( Math.random() * 10 );
      }
      if ( this.isDisabeld ) {
        // 可评论状态
        let data = {
          wallId: this.cardAndPhoto.id,
          userId: this.userId,
          imgurl: randomImg,
          moment: new Date(),
          name: this.author,
          comment: this.commentText,
        };
        await insertCommentApi( data ).then( ( res ) => {
          if ( res.code == 200 ) {
            this.cardAndPhoto.comcount[0].count++;
            this.comment.unshift( data );
            this.$message( { type: "success", message: "评论成功" } );
            this.commentText = "";
            this.$emit( 'clearCache' );
          }
        } );
      }
    },

    /**获取评论 */
    async getCommentData () {
      if ( this.pageNum > 0 ) {
        await findCommentPageApi( {
          id: this.cardAndPhoto.id,
          page: this.pageNum,
          pagesize: this.pageSize,
        } ).then( ( response ) => {
          if ( this.pageNum != 1 ) {
            this.comment = this.comment.concat( response.message );
          } else {
            this.comment = response.message;
          }
          // 是否还有下一页
          if ( response.message.length == this.pageSize ) {
            this.pageNum++;
          } else {
            this.pageNum = 0;
          }
        } );
      }
    },

    /** 联系墙主撕掉该便签 */
    async onDeleteNote () {
      if ( this.cardAndPhoto.cancel[0].count == 0 ) {
        let queryParams = {
          wallId: this.cardAndPhoto.id,
          userId: this.userId,
          type: 1, // 0喜欢 1撕掉 2举报
          moment: new Date(),
        };
        await insertFeedbackApi( queryParams ).then( () => {
          this.$message( { type: "success", message: '联系成功, 请稍等' } );
          this.$emit( 'waitDelete', queryParams.type );
          this.$emit( 'clearCache' );
        } ).catch( ( err ) => {
          console.log( err );
          this.$message( { type: "error", message: '联系失败, 小忆梦游中' } );
        } );
      } else {
        this.$message( { type: "warning", message: '墙主已收到' } );
        this.$emit( 'clearCache' );
      }
    },

    /** 举报 */
    async onReport () {
      if ( this.cardAndPhoto.report[0].count == 0 ) {
        let queryParams = {
          wallId: this.cardAndPhoto.id,
          userId: this.userId,
          type: 2, // 0喜欢 1撕掉 2举报
          moment: new Date(),
        };
        await insertFeedbackApi( queryParams ).then( () => {
          this.$message( { type: "success", message: '举报成功' } );
          this.$emit( 'haveReportInfo', queryParams.type );
          this.$emit( 'clearCache' );
        } ).catch( ( err ) => {
          console.log( err );
          this.$message( { type: "error", message: '举报失败, 小忆梦游中' } );
        } );
      } else {
        this.$message( { type: "warning", message: '墙主已收到' } );
        this.$emit( 'clearCache' );
      }
    },

    /** 删除便签 */
    onAdminDeleteNote () {
      deleteWallApi( { id: this.cardAndPhoto.id, imgurl: this.cardAndPhoto.imgurl || null } ).then( () => {
        this.$message( { type: "success", message: '删除成功' } );
        this.$emit( 'alreadyDelete', this.cardAndPhoto.id );
        this.$emit( 'clearCache' );
      } ).catch( ( err ) => {
        console.log( err );
        this.$message( { type: "error", message: '删除失败, 小忆梦游中' } );
      } );
    },

    /** 打开删除弹窗 */
    openDeleteComment ( item ) {
      this.isMySend = item.userId == this.userId;
      if ( this.isDeleteComment == item.id ) {
        this.isDeleteComment = false;
      } else {
        this.isDeleteComment = item.id;
      }
    },

    /** 删除评论 */
    onDeleteCommentData ( id ) {
      deleteCommentApi( { id: id } ).then( ( response ) => {
        if ( response.code == 200 ) {
          this.$message( { type: "success", message: '删除成功' } );
          this.pageNum = this.pageNum - 1 > 0 ? this.pageNum - 1 : 1;
          this.getCommentData().then( () => { this.cardAndPhoto.comcount[0].count--; } );
          this.$emit( 'clearCache' );
        } else {
          this.$message( { type: "error", message: '删除失败, 小忆留恋中' } );
        }
      } );
    },
  },

};
</script>
<style lang="less" scoped>
.card-detail {
  position: relative;
  width: 100%;
  padding: 0 @padding-20;
  box-sizing: border-box;

  .top-btn {
    .flex-s;
    position: fixed;
    top: 24px;
    left: 20px;
    gap: 30px;
    z-index: 90;

    .revoke {
      color: @primary-color;
      cursor: pointer;
    }

    .report {
      color: @warning-color;
      cursor: pointer;
    }
  }

  .card-main {
    margin-top: @padding-12;
  }

  .form {
    margin-top: @padding-12;

    .message {
      background: none;
      width: 100%;
      border: 1px solid @gray-3;
      resize: none;
      height: 42px;
      padding: @padding-8;
      box-sizing: border-box;
      border-radius: 3px;
      font-family: qiantuxianmoti;
      .copyable;
    }

    .button {
      .flex-b;
      margin-top: @padding-12;
      gap: 20px;

      .author {
        width: 100%;
        box-sizing: border-box;
        padding: @padding-8;
        background: none;
        border: 1px solid @gray-3;
        line-height: 20px;
        font-family: qiantuxianmoti;
        border-radius: 3px;
        font-size: 15px;
        .copyable;
      }
    }

    .message::placeholder,
    .author::placeholder {
      color: @gray-3;
      font-family: qiantuxianmoti;
      font-size: 15px;
    }
  }

  .title {
    font-weight: 600;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 20px;

    .count {
      padding: 0 5px;
      margin-left: 10px;
      border-bottom: 2px solid @gray-1;
    }
  }

  .comment {
    position: relative;
    padding-bottom: 36px;

    .comment-item {
      display: flex;
      margin-bottom: @padding-12;

      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        // background: @gray-3;
        overflow: hidden;
        margin-right: 10px;

        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .comment-main {
        position: relative;
        width: calc(100% - 50px);
        overflow-x: hidden;

        .comment-head {
          .flex-s;
          gap: @padding-8;

          .name {
            font-size: @size-14;
            color: @gray-1;
            font-weight: 550;
          }

          .time {
            font-size: @size-14;
            color: @gray-2;
          }
        }

        .comment-body {
          width: 100%;
          margin: 6px auto;
          font-size: 14px;
          color: @gray-2;
          line-height: 1.6;
          text-align: left;
          white-space: normal;
          /* 允许文本自动换行，这是默认值，也可以省略 */
          word-wrap: break-word;
          /* 防止长单词或URL溢出容器 */
        }

        .delete-comment {
          position: absolute;
          top: 0;
          right: 5px;
          font-size: 12px;
          font-weight: 100;
          letter-spacing: 1px;
          text-align: center;
          color: @gray-9;
          cursor: pointer;
          border-radius: 10px;
          padding: 2px 14px;
          background-color: @gray-3;
          transition: @tr;
        }
      }
    }

    .load-down {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 36px;
      line-height: 36px;
      border-radius: 36px;
      cursor: pointer;
      letter-spacing: 2px;
      font-size: 14px;
      font-weight: 550;
      transition: @tr;

      &:hover {
        background-color: @gray-10;
        backdrop-filter: blur(50px);
      }
    }
  }

  .no-comment {
    &>img {
      margin: 20px auto;
      width: 50%;
    }
  }
}

.imgBox {
  width: 100%;
}
</style>
