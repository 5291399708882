<template>
  <div class="login-container">
    <!-- <img
      :src="theme == 'dark' ? require('../../assets/images/backImage.jpg') : require('../../assets/images/backImage2.jpg')"
      alt="背景图片" class="back-image"> -->
    <!-- 登录 -->
    <div class="cont" :class="{ 's--signup': toggleType }">
      <div class="form sign-in" v-if="!isEditPassword">
        <SGYButton small line class="backIndexPage" @click="onBackIndexPage">返回主页</SGYButton>
        <h2>欢迎 回来(●'◡'●)</h2>
        <label>
          <span class="label">账号</span>
          <input type="text" v-model="queryParamsLogin.name" />
        </label>
        <label>
          <span class="label">密码</span>
          <input :type="isShowPassword ? 'text' : 'password'" v-model="queryParamsLogin.password" />
          <button class="iconfont icon-yanjing" :style="{ 'filter': !isShowPassword ? 'grayscale(100%)' : '' }"
            @click="isShowPassword = !isShowPassword"></button>
        </label>
        <button type="button" class="submit" @click="findUserLoginData">立即登录</button>
        <button type="button" class="submit" @click="isEditPassword = true">忘记密码</button>
      </div>
      <!-- 修改密码 -->
      <div class="form sign-in" v-if="isEditPassword">
        <SGYButton small line class="backIndexPage" @click="onBackIndexPage">返回主页</SGYButton>
        <h2>修改账号密码</h2>
        <label title="请以字母开头、可包含数字 下划线或点">
          <span class="label">请输入新账号</span>
          <input class="placeholder" placeholder="Admin123_" type="text" v-model="editPassword.name"
            @change="RegExpName(editPassword.name)" />
        </label>
        <label title="请以字母开头、可包含数字 下划线或点">
          <span class="label">请输入新密码</span>
          <input class="placeholder" placeholder="Admin123." type="text" v-model="editPassword.password"
            @change="RegExpPassword(editPassword.password)" />
        </label>
        <label>
          <span class="label">邮箱</span>
          <input type="email" v-model="editPassword.email" @change="RegExpEmail(editPassword.email)" />
        </label>
        <label class="div-label">
          <span class="label">验证码</span>
          <input type="text" v-model="vCode" />
          <button title="输入账号、密码、邮箱后可发送" class="iconfont icon-fasong"
            :style="{ 'filter': (!isName || !isPassword || !isEmail || (vCodeTimer && vCodeTimer != -1)) ? 'grayscale(100%)' : '' }"
            :disabled="(!isName || !isPassword || !isEmail || (vCodeTimer && vCodeTimer != -1))"
            @click="sendVCode(editPassword.email)">
          </button>
        </label>
        <div class="div-timer" v-show="vCodeTimer != -1 && vCodeTimer">{{ vCodeTimer + '秒后可发送' }}</div>
        <button type="button" class="submit submit2" @click="onForgetPassword"
          :disabled="(!isName || !isPassword || !isEmail)" v-if="isEditPassword">确认修改</button>
        <button type="button" class="submit submit2" @click="isEditPassword = false">前往登录</button>
      </div>
      <!-- 注册 -->
      <div class="sub-cont">
        <div class="img">
          <div class="img__text m--up" v-show="!isEditPassword">
            <h2>新来的？</h2>
            <p>来留下属于自己最美的回忆！</p>
          </div>
          <div class="img__text m--up" v-show="isEditPassword">
            <h2>再来一次吧！</h2>
            <p>修改成功后，使用新账号、新密码进行登录即可</p>
            <br>
            <p>请参考以下格式进行修改</p>
            <p>Admin123_</p>
            <p>kobe999.</p>
            <p>PengYuYan..</p>
          </div>
          <div class="img__text m--in">
            <h2>我们中的一员？</h2>
            <p>如果您已经有一个帐户，只需登录。我们想念你！</p>
            <br>
            <p>请参考以下格式进行注册</p>
            <p>Admin123_</p>
            <p>kobe999.</p>
            <p>PengYuYan..</p>
          </div>
          <div class="img__btn" @click="toggleType = !toggleType">
            <span class="m--up">注册</span>
            <span class="m--in">登录</span>
          </div>
        </div>
        <div class="form sign-up">
          <SGYButton small line class="backIndexPage" @click="onBackIndexPage">返回主页</SGYButton>
          <h2>有家的感觉了(*^▽^*)</h2>
          <label title="请以字母开头、可包含数字 下划线或点">
            <span class="label">账号</span>
            <input class="placeholder" placeholder="Admin123_" type="text" v-model="queryParams.name"
              @change="RegExpName(queryParams.name)" />
          </label>
          <label title="请以字母开头、可包含数字 下划线或点">
            <span class="label">密码</span>
            <input class="placeholder" placeholder="Admin123." type="text" v-model="queryParams.password"
              @change="RegExpPassword(queryParams.password)" />
          </label>
          <label title="方便找回密码">
            <span class="label">邮箱</span>
            <input type="email" v-model="queryParams.email" @change="RegExpEmail(queryParams.email)" />
          </label>
          <label class="div-label">
            <span class="label">验证码</span>
            <input type="text" v-model="vCode" />
            <button title="输入账号、密码、邮箱后可发送" class="iconfont icon-fasong"
              :style="{ 'filter': (!isName || !isPassword || !isEmail || (vCodeTimer && vCodeTimer != -1)) ? 'grayscale(100%)' : '' }"
              :disabled="(!isName || !isPassword || !isEmail || (vCodeTimer && vCodeTimer != -1))"
              @click="sendVCode(queryParams.email)">
            </button>
          </label>
          <div class="div-timer" v-show="vCodeTimer != -1 && vCodeTimer">{{ vCodeTimer + '秒后可发送' }}</div>
          <button type="button" :disabled="(!isName || !isPassword || !isEmail)" class="submit"
            @click="insertUserData">立即注册</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'LoginPcVue' };
</script>
<script setup>
import { watch, ref } from 'vue';
import { insertRegisterApi, getVerifyCodeApi, findUserLoginApi, updatePasswordApi } from '@/api/index';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Message from '@/components/SGY/message/message';
import SGYButton from '@/components/SGYButton.vue';
import { setAccessToken, setRefreshToken } from "@/store/localStorage";
/** 导入 */

/** 实例化 */
const store = useStore();
const router = useRouter();

/** 变量 */
const toggleType = ref( true ); // 切换登录与注册
const isEditPassword = ref( false ); // 切换修改密码
const vCode = ref( '' ); // 验证码
const isEmail = ref( false ); // 邮箱
const isPassword = ref( false ); // 密码
const isName = ref( false ); // 账号
const vCodeTimer = ref( -1 ); // 验证码计时器
const isShowPassword = ref( false ); // 查看密码
const queryParams = ref( { // 用户注册请求数据
  name: '',
  password: '',
  email: '',
  ip: '',
  moment: new Date(),
  competence: 1,
  vCode: '',
  avatar: '',
  nickname: '',
} );
const queryParamsLogin = ref( { // 用户登录请求数据
  name: '',
  password: '',
} );
const editPassword = ref( { // 用户修改密码请求数据
  name: '',
  password: '',
  email: '',
  vCode: '',
} );
const theme = ref( '' ); // 主题
/** 计算属性 */
watch( () => store.state.theme, ( nv ) => {
  theme.value = nv;
  document.body.setAttribute( 'data-theme', nv );
}, { immediate: true } );
watch( isEditPassword, ( nv ) => {
  if ( !nv ) vCode.value = '';
} );
watch( toggleType, ( nv ) => {
  if ( nv ) vCode.value = '';
} );
/** 函数 */
// 注册
const insertUserData = async () => {
  if ( !vCode.value ) return;
  queryParams.value.ip = store.state.ip;
  queryParams.value.vCode = vCode.value;
  await insertRegisterApi( queryParams.value ).then( ( res ) => {
    Message( { type: res.code == 200 ? 'success' : 'warning', message: res.message } );
    if ( res.code == 200 ) {
      toggleType.value = false;
      queryParamsLogin.value.name = queryParams.value.name;
      queryParamsLogin.value.password = queryParams.value.password;
    }
  } ).catch( () => {
    Message( { type: 'error', message: '验证条件失败' } );
    setTimeout( () => router.go( 0 ), 1000 );
  } );
};
// 登录
const findUserLoginData = async () => {
  if ( queryParamsLogin.value.name && queryParamsLogin.value.password ) {
    await findUserLoginApi( queryParamsLogin.value ).then( ( res ) => {
      Message( { type: 'success', message: '登录成功' } );
      setAccessToken( res.accessToken );
      setRefreshToken( res.refreshToken );
      store.commit( 'setIp', res.message.ip );
      router.replace( { path: '/' } );
    } ).catch( () => {
      Message( { type: 'error', message: '账号或密码错误' } );
    } );
  }
};
// 修改密码
const onForgetPassword = async () => {
  if ( !vCode.value ) return;
  editPassword.value.vCode = vCode.value;
  await updatePasswordApi( editPassword.value ).then( ( res ) => {
    Message( { type: res.code == 200 ? 'success' : 'warning', message: res.message } );
    if ( res.code == 200 ) {
      isEditPassword.value = false;
      queryParamsLogin.value.name = editPassword.value.name;
      queryParamsLogin.value.password = editPassword.value.password;
    }
  } ).catch( () => {
    Message( { type: 'error', message: '验证条件失败' } );
    setTimeout( () => router.go( 0 ), 1000 );
  } );
};
// 发送验证码
const sendVCode = async ( value ) => {
  if ( !isEmail.value || !isPassword.value || !isName.value ) return;
  await getVerifyCodeApi( {
    email: value,
  } ).then( ( res ) => {
    Message( { type: 'success', message: '验证码已发送' } );
    startTimer();
  } ).catch( ( err ) => {
    console.log( err );
    Message( { type: 'error', message: '小忆梦游中' } );
  } );
};
// 验证码计时器
const startTimer = () => {
  let time = 120;
  const timer = setInterval( () => {
    if ( time == 0 ) {
      clearInterval( timer );
      vCodeTimer.value = false;
      return;
    }
    vCodeTimer.value = time;
    time--;
  }, 1000 );
};
const RegExpEmail = ( email ) => {
  const reg = new RegExp( "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$" ); //正则表达式
  if ( !reg.test( email.trim() ) ) {
    isEmail.value = false;
    Message( { type: 'error', message: '请输入正确的邮箱地址' } );
  } else {
    isEmail.value = true;
  }
};
const RegExpPassword = ( password ) => {
  const reg = new RegExp( "^[a-zA-Z]{1}([a-zA-Z0-9]|[._]){4,19}$" ); //正则表达式
  if ( !reg.test( password.trim() ) ) {
    isPassword.value = false;
    Message( { type: 'error', message: '格式错误' } );
  } else {
    isPassword.value = true;
  }
};
const RegExpName = ( name ) => {
  const reg = new RegExp( "^[a-zA-Z]{1}([a-zA-Z0-9]|[._]){4,19}$" ); //正则表达式
  if ( !reg.test( name.trim() ) ) {
    isName.value = false;
    Message( { type: 'error', message: '格式错误' } );
  } else {
    isName.value = true;
  }
};

const onBackIndexPage = () => {
  router.replace( { path: '/' } );
}

/** 钩子 */
</script>
<style lang="less" scoped>
.login-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
  background-color: @gray-9;
  .flex
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background: #ededed;
}

input,
button {
  border: none;
  outline: none;
  background: none;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.cont {
  overflow: hidden;
  position: relative;
  width: 900px;
  height: 550px;
  margin: 0 auto 100px;
  background: @gray-10;

  &.s--signup {
    .sub-cont {
      transform: translate3d(-640px, 0, 0);
    }

    .img {
      &:before {
        transform: translate3d(640px, 0, 0);
      }
    }

    .img__text {
      &.m--up {
        transform: translateX(520px);
      }

      &.m--in {
        transform: translateX(0);
      }
    }

    .img__btn {
      span {
        &.m--in {
          transform: translateY(0);
        }

        &.m--up {
          transform: translateY(72px);
        }
      }
    }

    .sign-in {
      transition-timing-function: ease-in-out;
      transition-duration: 1.2s;
      transform: translate3d(720px, 0, 0);
    }

    .sign-up {
      transform: translate3d(0, 0, 0);
    }
  }
}

.form {
  position: relative;
  width: 640px;
  height: 100%;
  transition: transform 1.2s ease-in-out;
  padding: 50px 30px 0;
}

.backIndexPage {
  position: absolute;
  top: 20px;
  right: 30px;
  width: unset;
}

.sub-cont {
  overflow: hidden;
  position: absolute;
  left: 640px;
  top: 0;
  width: 900px;
  height: 100%;
  padding-left: 260px;
  background: @gray-10;
  transition: transform 1.2s ease-in-out;
}

button {
  display: block;
  margin: 0 auto;
  width: 260px;
  height: 36px;
  border-radius: 30px;
  color: @gray-10;
  font-size: 15px;
  cursor: pointer;
}

.img {
  overflow: hidden;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 260px;
  height: 100%;
  padding-top: 360px;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 900px;
    height: 100%;
    // background-image: url("../../assets/images/loginImage.jpg");
    // background-position: center bottom;
    // background-size: cover;
    background-color: #fff;
    transition: transform 1.2s ease-in-out;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
}

.img__text {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  color: @gray-10;
  transition: transform 1.2s ease-in-out;

  h2 {
    margin-bottom: 10px;
    font-weight: normal;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
  }

  &.m--in {
    transform: translateX(-520px);
  }
}

.img__btn {
  overflow: hidden;
  z-index: 2;
  position: relative;
  width: 100px;
  height: 36px;
  margin: 0 auto;
  background: transparent;
  color: @gray-10;
  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;

  &:after {
    content: "";
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid @gray-10;
    border-radius: 30px;
  }

  span {
    .flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: transform 1.2s;

    &.m--in {
      transform: translateY(-72px);
    }
  }
}

h2 {
  width: 100%;
  font-size: 26px;
  text-align: center;
}

label {
  position: relative;
  display: block;
  width: 260px;
  margin: 25px auto 0;
  text-align: center;

  span {
    font-size: 12px;
    color: #cfcfcf;
    text-transform: uppercase;
  }

  .icon-fasong {
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 26px;
    font-size: 26px;
    color: @success-color;
  }

  .icon-yanjing {
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 26px;
    font-size: 26px;
    color: @success-color;
  }
}

input {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  text-align: center;
}

.div-label input {
  padding: 0 50px 5px 50px;
}

.forgot-pass {
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  color: #cfcfcf;
}

.submit {
  margin-top: 40px;
  margin-bottom: 20px;
  background: @success-color;
  text-transform: uppercase;
}

.submit2 {
  margin-top: 20px;
}

.fb-btn {
  border: 2px solid #d3dae9;
  color: #8fa1c7;

  span {
    font-weight: bold;
    color: #455a81;
  }
}

.sign-in {
  transition-timing-function: ease-out;
}

.sign-up {
  transform: translate3d(-900px, 0, 0);
}

.icon-link {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 32px;

  img {
    width: 100%;
    vertical-align: top;
  }
}

.icon-link--twitter {
  left: auto;
  right: 5px;
}

.link-footer {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.back-image {
  position: fixed;
  top: -35%;
  left: 0;
  width: 100%;
  background-size: auto;
  background-position: center;
  z-index: -1;
  filter: blur(12px);
}

.placeholder::placeholder {
  color: rgba(0, 0, 0, 0.2);
  font-size: @size-14;
}

.label {
  color: @gray-3;
}

.div-timer {
  color: @gray-3;
  text-align: center;
}
</style>